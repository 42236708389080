import { FormControl, Grid, InputLabel, MenuItem, Select, Typography, } from "@mui/material";
import { ToggleSurface } from "../../common/core/Surface";
import { useTranslation } from "react-i18next";
import ResponsivePage from "../../common/core/ResponsivePage";
import { useEffect, useMemo, useState } from "react";
import { useAuth } from "@emberly/zenith-client";
import axios from "axios";
import config from "../../../config";
import SimpleTable from "../../common/tables/SimpleTable";

const MONTHS = [
  "Januar",
  "Februar",
  "Mars",
  "April",
  "Mai",
  "Juni",
  "Juli",
  "August",
  "September",
  "Oktober",
  "November",
  "Desember",
];

const cy = new Date().getFullYear();
const cd = (cy - 2020) + 1;
const YEARS = Array(cd).fill(1).map((_, i) => cy - i);

export default function ConsumptionPage() {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth();

  const [stations, setStations] = useState([]);
  const [working, setWorking] = useState(true);

  const [year, setYear] = useState(cy);
  const [month, setMonth] = useState(new Date().getMonth());
  const [consumption, setConsumption] = useState([]);

  const columns = useMemo(() => {
    return [
      {
        field: "station",
        headerName: t("consumption:fieldStation"),
        valueGetter: (params) => stations.find(t => t.id === params.row.organizationId)?.displayName || "-",
        minWidth: 200
      },
      {
        field: "stationKey",
        headerName: t("consumption:fieldStationKey"),
        valueGetter: (params) => stations.find(t => t.id === params.row.organizationId)?.name || "-",
        minWidth: 200
      },
      {
        field: "organizationId",
        headerName: t("consumption:fieldStationId"),
        minWidth: 200
      },
      {
        field: "missions",
        type: "number",
        headerName: t("consumption:fieldMissions"),
        minWidth: 200
      },
      {
        field: "tollFeeRequests",
        type: "number",
        headerName: t("consumption:fieldTollFeeRequests"),
        minWidth: 200
      },
    ]
  }, [t, stations]);


  useEffect(() => {

    // Fetch all users in organization
    const fetchStations = async () => {
      try {
        const res = await axios.get("/api/globaladmin/stations", {
          headers: { Authorization: `Bearer ${await getAccessTokenSilently()}` },
        });

        setStations(res.data);

      } catch (err) {
        console.log(err);
      }
    };

    fetchStations();

  }, [getAccessTokenSilently]);


  useEffect(() => {

    const fetchConsumption = async () => {

      try {
        setWorking(true);

        const res = await axios.get(`/api/globaladmin/consumption/${year}/${month + 1}`, {
          headers: { Authorization: `Bearer ${await getAccessTokenSilently()}` },
        });

        setConsumption(res.data);

      } catch (err) {
        console.log(err);
      } finally {
        setWorking(false);
      }
    };

    if (stations.length !== 0) {
      fetchConsumption();
    }

  }, [month, year, getAccessTokenSilently, stations]);


  return (
    <ResponsivePage
      maxWidth={config.pageSize.medium}
      title={t("consumption:header")}
      backButton
    >
      <Grid item xs={12}>
        <Typography variant="body1">{t("consumption:description")}</Typography>
      </Grid>

      <ToggleSurface
        title={t("consumption:monthSelectorTitle")}
        xs={12}
        lg={6}
      >
        <Grid item container spacing={3}>
          <Grid item xs={6}>
            <FormControl fullWidth size="small" variant="filled">
              <InputLabel>{t("common:year")}</InputLabel>
              <Select onChange={ev => setYear(ev.target.value)} value={year}>
                {YEARS.map((y) => <MenuItem value={y} key={y}>{y}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth size="small" variant="filled">
              <InputLabel>{t("common:month")}</InputLabel>
              <Select onChange={ev => setMonth(ev.target.value)} value={month}>
                {MONTHS.map((m, i) => <MenuItem value={i} key={i}>{m}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </ToggleSurface>

      <ToggleSurface
        title={t("consumption:header")}
      >
        <SimpleTable
          tableId="consumption"
          loading={working}
          rows={consumption}
          columns={columns}
          emptyStateTitle={t("consumption:emptyStateTitle")}
          emptyStateDescription={t("consumption:emptyStateDescription")}
        />
      </ToggleSurface>

    </ResponsivePage>
  );
}
