import React, { createContext, useContext, useCallback, useState } from "react";
import { useMission } from "./MissionProvider";
import { AssumeTaskState, AssumeTaskStateEnum, MakeStateChange, ShouldAskForUpdatedPosition } from "../common/mission";
import { useTranslation } from "react-i18next";
import { ActivityEnums, TaskEnums } from "../common/constants";
import { Task, useAuth } from "@emberly/zenith-client";
import { useStation } from "./StationProvider";
import { MakeActivity } from "../common/activity";
import { useNotification } from "./NotificationProvider";
import { useDriver } from "./DriverProvider";
import UpdateLocationDialog from "../components/common/inputs/UpdateLocationDialog";
import { useNavigate } from "react-router-dom";


export const MissionTaskContext = createContext();
export const useMissionTask = () => useContext(MissionTaskContext);

export default function MissionTaskContextProvider(props) {
  const { id } = props;
  const { t } = useTranslation();
  const { user } = useAuth();
  const { logEvent, station } = useStation();
  const { mission, updateMissionField, locked } = useMission();
  const missionTask = mission?.salvageTasks?.find(t => t.taskId === id);
  const taskIndex = mission?.salvageTasks?.findIndex(t => t.taskId === id);
  const { snackbar } = useNotification();
  const { geolocation, vehicleDetails } = useDriver();
  const navigate = useNavigate();
  const [updateLocationAction, setUpdateLocationAction] = useState(null);

  const notFound = !!mission && !missionTask;

  const taskState = AssumeTaskStateEnum(missionTask);
  const taskPath = `salvageTasks.${taskIndex}`
  const lastTaskState = AssumeTaskState(missionTask);
  const hasTransport = !missionTask?.resolvedAtLocation;

  const driverAssignedState = missionTask?.execution?.driverAssignedState || TaskEnums.DriverAssignedState.Unknown;


  const logTaskEvent = useCallback((type, value = "", description = "") => {
    logEvent(MakeActivity(ActivityEnums.Category.Mission, type, mission?.id, value, description, { id, name: missionTask.taskName }));
  }, [logEvent, mission, missionTask, id]);

  const continueMission = useCallback(async () => {

    if (driverAssignedState === TaskEnums.DriverAssignedState.Pending) {
      updateMissionField(`${taskPath}.execution.driverAssignedState`, TaskEnums.DriverAssignedState.Accepted);
      updateMissionField(`${taskPath}.execution.history.responded`, MakeStateChange(user));
      logTaskEvent(ActivityEnums.Type.AcceptedTask);

    } else {
      const eVehicle = missionTask?.execution?.vehicle;
      // update task vehicle if not matching

      if (
        taskState >= TaskEnums.State.Created &&
        taskState < TaskEnums.State.Completed &&
        !!vehicleDetails && !!vehicleDetails?.id &&
        (
          vehicleDetails.id !== eVehicle?.id ||
          vehicleDetails?.name !== eVehicle?.name ||
          vehicleDetails?.weight !== eVehicle?.weight ||
          vehicleDetails?.height !== eVehicle?.height ||
          vehicleDetails?.width !== eVehicle?.width ||
          vehicleDetails?.fuel !== eVehicle?.fuel ||
          vehicleDetails?.emissionClass !== eVehicle?.emissionClass
        )
      ) {
        updateMissionField(`${taskPath}.execution.vehicle`, vehicleDetails);
      }

      switch (taskState) {

        case TaskEnums.State.Created:
        case TaskEnums.State.Assigned:
        case TaskEnums.State.Responded:
          updateMissionField(`${taskPath}.execution.history.started`, MakeStateChange(user, geolocation));
          logTaskEvent(ActivityEnums.Type.TaskStarted);
          snackbar(`${t("common:stateUpdated")} "${t("task:enums:state:Started")}"`);
          break;

        case TaskEnums.State.Started:
          // TODO update potential location here.
          if (ShouldAskForUpdatedPosition(geolocation, missionTask.route.waypoints[0])) {
            try {
              const action = new Task();
              setUpdateLocationAction(action);
              const resultLocation = await action.wait();
              if (resultLocation === "cancel") return;

              updateMissionField(
                `${taskPath}.route.waypoints`,
                missionTask.route.waypoints.length <= 1 ?
                  [resultLocation.getData()] :
                  [resultLocation.getData(), ...missionTask.route.waypoints.slice(1)]
              );

            } catch { } finally {
              setUpdateLocationAction(null);
            }
          }

          updateMissionField(`${taskPath}.execution.history.arrived`, MakeStateChange(user, geolocation));
          logTaskEvent(ActivityEnums.Type.TaskArrived);
          snackbar(`${t("common:stateUpdated")} "${t("task:enums:state:Arrived")}"`);
          break;

        case TaskEnums.State.Arrived:
          if (hasTransport) {
            updateMissionField(`${taskPath}.execution.history.loaded`, MakeStateChange(user, geolocation));
            logTaskEvent(ActivityEnums.Type.TaskLoaded);
            snackbar(`${t("common:stateUpdated")} "${t("task:enums:state:Loaded")}"`);
          } else {
            updateMissionField(`${taskPath}.execution.history.completed`, MakeStateChange(user, geolocation));
            logTaskEvent(ActivityEnums.Type.TaskCompleted);
            snackbar(`${t("common:stateUpdated")} "${t("task:enums:state:Completed")}"`);
          }
          break;

        case TaskEnums.State.Loaded:
          if (station?.includeArrivedDest) {
            updateMissionField(`${taskPath}.execution.history.arrivedDest`, MakeStateChange(user, geolocation));
            logTaskEvent(ActivityEnums.Type.TaskArrivedDest);
            snackbar(`${t("common:stateUpdated")} "${t("task:enums:state:ArrivedDest")}"`);
          } else {
            updateMissionField(`${taskPath}.execution.history.completed`, MakeStateChange(user, geolocation));
            logTaskEvent(ActivityEnums.Type.TaskCompleted);
            snackbar(`${t("common:stateUpdated")} "${t("task:enums:state:Completed")}"`);
          }
          break;

        case TaskEnums.State.ArrivedDest:
          updateMissionField(`${taskPath}.execution.history.completed`, MakeStateChange(user, geolocation));
          logTaskEvent(ActivityEnums.Type.TaskCompleted);
          snackbar(`${t("common:stateUpdated")} "${t("task:enums:state:Completed")}"`);
          break;


        case TaskEnums.State.Completed:
          updateMissionField(`${taskPath}.execution.history.acknowledged`, MakeStateChange(user));
          logTaskEvent(ActivityEnums.Type.TaskAcknowledged);
          snackbar(`${t("common:stateUpdated")} "${t("task:enums:state:Acknowledged")}"`);
          break;

        default:
          console.log("err state");
      }

    }
  }, [updateMissionField, taskPath, driverAssignedState, taskState, user, hasTransport, logTaskEvent, snackbar, t, geolocation, missionTask, vehicleDetails, station]);

  const rejectTask = useCallback(async () => {
    updateMissionField(`${taskPath}.execution.driverAssignedState`, TaskEnums.DriverAssignedState.Rejected);
    updateMissionField(`${taskPath}.execution.history.responded`, MakeStateChange(user));
    logTaskEvent(ActivityEnums.Type.RejectedTask);
    navigate("/mymissions", { replace: true, relative: true });
  }, [updateMissionField, taskPath, logTaskEvent, user, navigate]);

  return (
    <MissionTaskContext.Provider
      value={{
        taskId: id,
        locked,
        missionTask,
        lastTaskState,
        mission,
        taskState,
        updateMissionField,
        taskPath,
        taskIndex,
        hasTransport,
        driverAssignedState,
        rejectTask,
        continueMissionText: getContinueMissionText(t, taskState, hasTransport, driverAssignedState, station),
        continueMission,
        notFound
      }}
    >
      {props.children}
      {!!updateLocationAction ? <UpdateLocationDialog task={updateLocationAction} geolocation={geolocation} waypoint={missionTask.route.waypoints[0]} /> : null}
    </MissionTaskContext.Provider>
  );
}



function getContinueMissionText(t, taskState, hasTransport, driverAssignedState, station) {
  if (driverAssignedState === TaskEnums.DriverAssignedState.Pending) return t("task:common:accept");

  switch (taskState) {
    case TaskEnums.State.Created:
    case TaskEnums.State.Assigned:
    case TaskEnums.State.Responded:
      return t("task:enums:state:Started");

    case TaskEnums.State.Started:
      return t("task:enums:state:Arrived");

    case TaskEnums.State.Arrived:
      if (hasTransport) {
        return t("task:enums:state:Loaded");
      } else {
        return t("task:enums:state:Completed");
      }

    case TaskEnums.State.Loaded:
      return station?.includeArrivedDest ? t("task:enums:state:ArrivedDest") : t("task:enums:state:Completed");

    case TaskEnums.State.ArrivedDest:
      return t("task:enums:state:Completed");

    case TaskEnums.State.Completed:
      return t("task:enums:state:Acknowledged");

    default:
      return null;
  }
}
