import { useCallback, useMemo, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { Grid, Table, TableBody, TableRow, TableCell, Button, Alert } from "@mui/material";
import { ToggleSurface } from "../../../common/core/Surface";
import { useTranslation } from "react-i18next";
import { useEntity, EntityFieldTypes } from "@emberly/zenith-client";
import EntityEditDialog from "../../../common/inputs/EntityEditDialog";
import { country } from "../../../../common/helpers";

const useStyles = makeStyles()(
  (theme) => ({
    fieldTitle: {
      fontWeight: "600",
    },
    table: {
      "& td": {
        border: "none",
        padding: theme.spacing(1),
        paddingLeft: 0,
        verticalAlign: "top"
      }
    }
  })
);

export default function CustomerInformationCard(props) {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { entity, updateEntity } = useEntity();
  const [dialog, setDialog] = useState(false);
  const billing = entity.billing;
  const missingCustomerData = !!billing && (!entity.customerNumber || !billing.address || !billing.country || !billing.postNumber || !billing.area);

  const fields = useMemo(() => [
    { name: t("entity:contact:customerNumber"), path: "customerNumber", dataType: "number", numberType: "int", serialization: "number", type: EntityFieldTypes.String, activeIfPath: "isCustomer" },
    { name: t("entity:location:country"), path: "billing.country", type: EntityFieldTypes.Country, activeIfPath: "isCustomer" },
    { name: t("contactRegister:fieldBillingAddress"), path: "billing.address", type: EntityFieldTypes.String, activeIfPath: "isCustomer" },
    { name: t("contactRegister:fieldBillingAddress2"), path: "billing.address2", type: EntityFieldTypes.String, activeIfPath: "isCustomer" },
    { name: t("contactRegister:fieldPostNumber"), path: "billing.postNumber", type: EntityFieldTypes.String, xs: 5, activeIfPath: "isCustomer" },
    { name: t("contactRegister:fieldArea"), path: "billing.area", type: EntityFieldTypes.String, xs: 7, activeIfPath: "isCustomer" },
    { name: t("contactRegister:fieldBillingEmail"), path: "billing.email", type: EntityFieldTypes.String, activeIfPath: "isCustomer", dataType: "email" },
  ], [t]);

  const onEditCancel = useCallback(() => {
    setDialog(false);
  }, []);

  const onEditConfirm = useCallback((values, updatedFields) => {
    setDialog(false);
    updateEntity(values, updatedFields);
  }, [updateEntity]);

  return (
    <ToggleSurface title={t("contact:customerInformationCard:title")} md={12} lg={6} collapse={!entity.isCustomer}>
      <Grid item container spacing={1}>

        {missingCustomerData ? (
          <Grid item xs={12}>
            <Alert color="warning">
              {t("contact:customerInformationCard:missingInformation")}
            </Alert>
          </Grid>
        ) : null}


        <Grid item>
          <Table className={classes.table}>
            <TableBody>

              <TableRow>
                <TableCell className={classes.fieldTitle}>{t("entity:contact:customerNumber")}</TableCell>
                <TableCell>{entity?.customerNumber || "-"}</TableCell>
              </TableRow>


              <TableRow>
                <TableCell className={classes.fieldTitle}>{t("contact:customerInformationCard:billingEmail")}</TableCell>
                <TableCell>{entity?.billing.email || "-"}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell className={classes.fieldTitle}>{t("contact:customerInformationCard:billingAddress")}</TableCell>
                <TableCell>{entity && entity.isCustomer ? `${entity.billing.address || "-"}${!!entity.billing.address2 ? ` (${entity.billing.address2}),` : ","} ${entity.billing.postNumber || "-"} ${entity.billing.area || "-"}, ${country(t, entity.billing.country) || "-"}` : "-"}</TableCell>
              </TableRow>

            </TableBody>
          </Table>
        </Grid>

        <Grid item xs={12} container justifyContent="flex-end">
          <Button variant="contained" color="neutral" size="medium" onClick={() => setDialog(true)}>
            {t("common:change")}
          </Button>
        </Grid>

      </Grid>

      {dialog ? (
        <EntityEditDialog
          editTitle={t("contact:customerInformationCard:editDialogTitle")}
          entity={entity}
          fields={fields}
          open={dialog}
          onCancel={onEditCancel}
          onUpdate={onEditConfirm}
        />
      ) : null}
    </ToggleSurface>
  );
}

