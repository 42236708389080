import { useTranslation } from "react-i18next";
import { Accordion, AccordionDetails, AccordionSummary, Box, Chip, Grid, List, ListItem, ListItemIcon, ListItemText, Skeleton, Stack, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { ExpandMore as ExpandMoreIcon, DirectionsBoatOutlined as DirectionsBoatIcon, TollOutlined as TollIcon, DiscountOutlined as DiscountIcon } from "@mui/icons-material";
import { TollFeeEnums, TollFeeEnumsLists } from "../../../../common/constants";
import { FixNumber } from "../../../../common/orders";
import { useMemo } from "react";

const useStyles = makeStyles()(
  (theme) => ({
    root: {
      width: "100%"
    },
    accordion: {
      //padding: 0,
      width: "100%",
      boxShadow: "none",
      background: "none",
      "& .MuiAccordionDetails-root": {
        padding: 0,
      },
      "& .MuiAccordionSummary-root": {
        height: "40px",
        padding: 0,
      },
      "& .MuiAccordionSummary-content": {
        margin: 0,
      }
    },
    list: {
      marginTop: theme.spacing(-1.5),
      paddingBottom: theme.spacing(1),
      "& li": {
        paddingTop: 0,
        paddingBottom: 0,
        "& .MuiListItemIcon-root": {
          minWidth: "44px"
        }
      },
    }
  })
);

export default function TollFeeDisplay(props) {
  const { loading, tollData } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <Stack className={classes.root}>
      {loading ? (
        <>
          <TollFeePointLoading defaultExpanded />
          <TollFeePointLoading />
        </>
      ) : tollData?.tollFeePoints?.map((tollFeePoint, key) => <TollFeePoint key={key} tollFeePoint={tollFeePoint} />)}
    </Stack>
  );
}


function TollFeePoint(props) {
  const { tollFeePoint, defaultExpanded } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();
  const totalPrice = useMemo(() => FixNumber(tollFeePoint.fees.reduce((acc, b) => acc + b.feeRebated, 0)), [tollFeePoint]);

  return (
    <Accordion defaultExpanded={defaultExpanded} className={classes.accordion} disableGutters>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography variant="subtitle2">{tollFeePoint.name}</Typography>
        <Typography variant="subtitle2" textAlign={"right"} sx={{ flexGrow: 1, mr: 1 }}>{totalPrice},-</Typography>

      </AccordionSummary>
      <AccordionDetails>
        <List disablePadding className={classes.list}>
          {tollFeePoint.hourlyRule ? (
            <ListItem>
              <ListItemIcon>
                <DiscountIcon />
              </ListItemIcon>
              <ListItemText
                primary={t("tollFees:common:hourlyRule")}
              />
            </ListItem>
          ) : null}
          {tollFeePoint.autopass && !!tollFeePoint.autopassDiscount ? (
            <ListItem>
              <ListItemIcon>
                <DiscountIcon />
              </ListItemIcon>
              <ListItemText
                primary={`${t("tollFees:common:autopassDiscount")} ${tollFeePoint.autopassDiscount}%`}
              />
            </ListItem>
          ) : null}
          {tollFeePoint.fees.map((fee, key) => <TollFee key={key} fee={fee} />)}
        </List>
      </AccordionDetails>
    </Accordion>
  );
}


function TollFee(props) {
  const { fee } = props;
  const isFerry = fee.feeType === TollFeeEnums.FeeType.Ferry;
  const { t } = useTranslation();

  return (
    <ListItem>
      <ListItemIcon>
        {isFerry ? <DirectionsBoatIcon /> : <TollIcon />}
      </ListItemIcon>
      <ListItemText
        primary={t(`tollFees:enums:feeType:${TollFeeEnumsLists.FeeType[fee.feeType]}`)}
        secondary={`${FixNumber(fee.feeRebated)},-`}
      />
    </ListItem>
  );
}





function TollFeePointLoading(props) {
  const { defaultExpanded } = props;
  const { classes } = useStyles();

  return (
    <Accordion defaultExpanded={defaultExpanded} className={classes.accordion} disableGutters>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      >
        <Skeleton variant="text" width={"100%"} />
      </AccordionSummary>
      <AccordionDetails>
        <List disablePadding className={classes.list}>
          <TollFeeLoading />
          <TollFeeLoading />
        </List>
      </AccordionDetails>
    </Accordion>
  );
}

function TollFeeLoading() {

  return (
    <ListItem>
      <ListItemIcon>
        <Skeleton variant="circular" width="32px" height="32px" />
      </ListItemIcon>
      <ListItemText
        primary={<Skeleton />}
        secondary={<Skeleton />}
      />
    </ListItem>
  );
}