import { Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo } from "react";
import { useEntityField, Defaults } from "@emberly/zenith-client";
import ContactPicker from "../../../../common/inputs/ContactPicker";
import ContactLink from "./ContactLink";
import { useMission } from "../../../../../providers/MissionProvider";
import AddressPicker from "../../../../common/inputs/AddressPicker";
import EmailTextField from "../../../../common/inputs/EmailTextField";

export default function ContactSection(props) {
  const { path, locked } = props;
  const { t } = useTranslation();
  const agreement = useEntityField("actors.contract.agreement", Defaults.Null);
  const { updateMissionField } = useMission();

  // Owner data
  const contactId = useEntityField(`${path}.id`, Defaults.String);
  const contactIsReferenced = useEntityField(`${path}.isReferenced`, Defaults.Boolean);
  const contactName = useEntityField(`${path}.name`, Defaults.String);
  const contactDescription = useEntityField(`${path}.description`, Defaults.String);
  const contactLocation = useEntityField(`${path}.location`, Defaults.Location);
  const contactEmail = useEntityField(`${path}.email`, Defaults.String);
  const contactPhoneNumber = useEntityField(`${path}.phoneNumber`, Defaults.String);
  const contactContactNumber = useEntityField(`${path}.contactNumber`, Defaults.Number);

  const onOwnerContactChanged = useCallback((name, contact) => {
    if (!!contact) {

      updateMissionField(path, {
        name: contact.name,
        id: contact.id,
        location: (!!contactLocation.value && !contactLocation.value?.isEmpty) ? contactLocation.value : contact.location,
        phoneNumber: contactPhoneNumber.value || contact.phoneNumber,
        email: contactEmail.value || contact.email,
        description: contact.description,
        contactNumber: contact.number || contact.contactNumber,
        customerNumber: contact.customerNumber,
        organizationNumber: contact.organizationNumber,
        isReferenced: !!contact.id,
        billing: contact.billing || {}
      });


      if (!!contact.standardAgreement && !agreement.value) {
        agreement.onChange(null, contact.standardAgreement);
      }

    } else {
      if (contactIsReferenced.value && contactName.value !== name) {
        contactIsReferenced.onChange(null, false);
        contactId.onChange(null, "");
      }
      contactName.onChange(null, name);
    }

  }, [path, updateMissionField, contactName.value, contactName.onChange, contactId.onChange, contactIsReferenced.value, contactIsReferenced.onChange, agreement.value, agreement.onChange, contactEmail.value, contactPhoneNumber.value, contactLocation.value]);

  const contactContact = useMemo(() => {
    return contactId.loading || contactName.loading ? null : { id: contactId.value, name: contactName.value };
  }, [contactId.value, contactName.value, contactId.loading, contactName.loading]);

  const onFetchInitialValues = useCallback(() => {
    return {
      name: contactName.value,
      email: contactEmail.value,
      phoneNumber: contactPhoneNumber.value,
      location: contactLocation.value,
    };
  }, [contactEmail.value, contactPhoneNumber.value, contactLocation.value, contactName.value]);

  return (
    <>
      <Grid item xs={12}>
        <ContactPicker
          disabled={locked}
          label={t("common:name")}
          onChange={onOwnerContactChanged}
          value={contactContact}
          fetchInitialValues={onFetchInitialValues}
        />
        <ContactLink
          open={contactIsReferenced.value && !!contactId.value}
          contactNumber={contactContactNumber.value}
          contactId={contactId.value}
          description={contactDescription.value}
        />
      </Grid>


      <Grid item xs={12}>
        <AddressPicker
          disabled={locked}
          picker
          freeSolo
          label={t("entity:location:address")}
          value={contactLocation.value}
          onChange={location => contactLocation.onChange(null, location)}
        />
      </Grid>

      <Grid item xs={12}>
        <EmailTextField
          disabled={locked}
          label={t("common:email")}
          value={contactEmail.value}
          onChange={contactEmail.onChange}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          disabled={locked}
          label={t("common:phoneNumber")}
          value={contactPhoneNumber.value}
          onChange={contactPhoneNumber.onChange}
          variant="filled"
          fullWidth
          size="small"
        />
      </Grid>
    </>
  );
}



