import { makeStyles } from "tss-react/mui";
import { Grid, Button, Typography } from "@mui/material";
import { ToggleSurface } from "../../../../common/core/Surface";
import { useTranslation } from "react-i18next";
import { useMission } from "../../../../../providers/MissionProvider";
import FilesSection from "../../../../common/sections/FilesSection";

const useStyles = makeStyles()(
  (theme) => ({
    titleContainer: {
      width: "100%",
      marginBottom: theme.spacing(2)
    }
  })
);

export default function FilesCard() {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { showFilesCard, onSelectFiles, locked } = useMission();

  return (
    <ToggleSurface
      collapse={!showFilesCard}
      md={12} lg={6}
      title={
        <Grid container direction="row" className={classes.titleContainer}>
          <Grid item xs>
            <Typography variant="h5">{t("mission:cards:files:title")}</Typography>
          </Grid>
          <Button component="label" disabled={locked}>
            {t("common:upload")}
            <input
              disabled={locked}
              type="file"
              hidden
              accept="*"
              onChange={onSelectFiles}
              multiple
            />
          </Button>
        </Grid>
      }
    >
      <FilesSection />
    </ToggleSurface>
  );
}
