import { makeStyles } from "tss-react/mui";
import { Grid, Typography, Button, Table, TableBody, TableRow, TableCell, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCallback, useState } from "react";
import { useEntityField, Defaults } from "@emberly/zenith-client";
import OverrideRouteDetailsDialog from "../../pages/mission/cards/tasks/common/OverrideRouteDetailsDialog";

const useStyles = makeStyles()(
  (theme) => ({
    root: {
      borderRadius: theme.spacing(1),
      padding: theme.spacing(2),
      paddingTop: theme.spacing(1),
      background: theme.palette.background.grey,
      opacity: 0,
      transition: "opacity 0.25s ease",
      height: "auto",
    },
    button: {
      marginRight: "-8px"
    },
    animateIn: {
      opacity: 1
    },
    table: {
      width: "100%",
      tableLayout: "fixed",
      "& td": {
        border: "none",
        padding: theme.spacing(1),
        paddingLeft: 0,
        paddingBottom: theme.spacing(0.25),
        paddingTop: theme.spacing(0.25),
        whiteSpace: "nowrap"
      },
      "& td:nth-of-type(1)": {
        width: "140px"
      },
      "& td:last-child": {
        textAlign: "right"
      }
    },
    inRouteIcon: {
      display: "inline-block",
      position: "relative",
      width: "16px",
      height: "16px",
      borderRadius: "50%",
      background: theme.palette.common.inRoute,
      marginBottom: "-2px"
    },
    toRouteIcon: {
      display: "inline-block",
      position: "relative",
      width: "16px",
      height: "16px",
      borderRadius: "50%",
      background: theme.palette.common.toRoute,
      marginBottom: "-2px"
    },
    invisibleIcon: {
      display: "inline-block",
      position: "relative",
      width: "16px",
      height: "16px",
      borderRadius: "50%",
      marginBottom: "-2px"
    },
    overridden: {
      textDecoration: "line-through"
    },
    divider: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    vehicleText: {
      paddingLeft: theme.spacing(2.5)
    }
  })
);

const sanitizeNumber = (n) => isNaN(n) || n === -1 ? 0 : n;
const pickLastValid = (a, b) => typeof b === "number" ? b : a;

export default function RouteDetailsCard(props) {
  const { path, locked, className } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { value: routeData } = useEntityField(`${path}.route`, Defaults.Object);
  const { value: vehicle } = useEntityField(`${path}.execution.vehicle`, Defaults.Object);

  const originalDistanceInRouteKM = sanitizeNumber(routeData?.distanceInRouteKM);
  const hasOverrideDistanceInRouteKM = typeof routeData?.overriddenDistanceInRouteKM === "number";

  const originalDistanceToRouteKM = sanitizeNumber(routeData?.distanceToRouteKM);
  const hasOverrideDistanceToRouteKM = typeof routeData?.overriddenDistanceToRouteKM === "number";

  const distanceInRouteKM = sanitizeNumber(pickLastValid(routeData?.distanceInRouteKM, routeData?.overriddenDistanceInRouteKM));
  const distanceToRouteKM = sanitizeNumber(pickLastValid(routeData?.distanceToRouteKM, routeData?.overriddenDistanceToRouteKM));

  const hasDimensionOverride = !!routeData.overriddenWidth || !!routeData.overriddenHeight || !!routeData.overriddenWeight;

  const [dialog, setDialog] = useState(false);

  const openDialog = useCallback(() => {
    setDialog(true);
  }, []);

  const closeDialog = useCallback(() => {
    setDialog(false);
  }, []);

  return (
    <div className={`${classes.root} ${className} ${props.in ? classes.animateIn : ""}`}>
      <Grid container alignItems="center">

        <Grid item xs>
          <Typography variant="subtitle1">{t("mission:cards:tasks:map:detailsCardTitle")}</Typography>
        </Grid>

        <Grid item>
          <Button className={classes.button} color="secondary" onClick={openDialog} disabled={locked}>{t("common:change")}</Button>
        </Grid>

        <Grid item xs={12}>
          <Table className={classes.table}>
            <TableBody>

              <TableRow>
                <TableCell>
                  <Typography color="textSecondary" variant="body1"><span className={classes.inRouteIcon}></span> {t("mission:cards:tasks:map:distanceInRoute")}</Typography>
                </TableCell>
                <TableCell>
                  {hasOverrideDistanceInRouteKM ? <Typography variant="body1" color="textSecondary" className={classes.overridden}>{originalDistanceInRouteKM} km</Typography> : null}
                </TableCell>
                <TableCell>
                  <Typography variant="body1">{distanceInRouteKM} km</Typography>
                </TableCell>
              </TableRow>


              <TableRow>
                <TableCell>
                  <Typography color="textSecondary" variant="body1"><span className={classes.toRouteIcon}></span> {t("mission:cards:tasks:map:distanceToRoute")}</Typography>
                </TableCell>

                <TableCell>
                  {hasOverrideDistanceToRouteKM ? <Typography variant="body1" color="textSecondary" className={classes.overridden}>{originalDistanceToRouteKM} km</Typography> : null}
                </TableCell>
                <TableCell>
                  <Typography variant="body1">{distanceToRouteKM} km</Typography>
                </TableCell>
              </TableRow>


              <TableRow>
                <TableCell>
                  <Typography color="textSecondary" variant="body1"><span className={classes.invisibleIcon}></span> {t("mission:cards:tasks:map:distanceTotal")}</Typography>
                </TableCell>
                <TableCell>
                  {hasOverrideDistanceToRouteKM || hasOverrideDistanceInRouteKM ? <Typography variant="body1" color="textSecondary" className={classes.overridden}>{originalDistanceInRouteKM + originalDistanceToRouteKM} km</Typography> : null}
                </TableCell>
                <TableCell>
                  <Typography variant="body1">{distanceInRouteKM + distanceToRouteKM} km</Typography>
                </TableCell>
              </TableRow>
              
            </TableBody>
          </Table>
        </Grid>

        {
          !!vehicle?.id ? (
            <>
              <Grid item xs={12}>
                <Divider className={classes.divider} />
              </Grid>

              <Grid item xs={12}>
                <Typography color="textSecondary" variant="body2" className={classes.vehicleText}>
                  {t("mission:cards:tasks:map:routeForVehicle")} {vehicle?.name}{hasDimensionOverride ? "," : "."} {hasDimensionOverride ? t("mission:cards:tasks:map:routeDimensionsOverridden") : ""}
                </Typography>
              </Grid>
            </>

          ) : null
        }

      </Grid>

      {!!dialog ? (
        <OverrideRouteDetailsDialog
          open
          onClose={closeDialog}
          path={path}
        />
      ) : null}

    </div>
  );
}
