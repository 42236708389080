import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import EntityTable from "../../common/tables/EntityTable";
import { EntityFieldTypes } from "@emberly/zenith-client";
import ResponsivePage from "../../common/core/ResponsivePage";
import config from "../../../config";

export default function VatCodeRegister() {
  const { t } = useTranslation();

  const columns = useMemo(() => {
    return [
      {
        field: "code",
        headerName: t("vatCode:fieldCode"),
        flex: 0.35,
        minWidth: 60,
      },
      {
        field: "name",
        headerName: t("common:name"),
        flex: 1,
        minWidth: 120,
      },
      {
        field: "rate",
        headerName: t("vatCode:fieldRate"),
        valueGetter: (params) => params.row.rate,
        valueFormatter: (params) => `${params.value}%`,
        flex: 0.5,
        minWidth: 80,
      },

    ]
  }, [t]);

  const fields = useMemo(() => [
    { name: t("vatCode:fieldCode"), path: "code", type: EntityFieldTypes.String, required: true },
    { name: t("common:name"), path: "name", type: EntityFieldTypes.String, required: true },
    { name: t("vatCode:fieldRate"), path: "rate", type: EntityFieldTypes.String, dataType: "number", required: true },
  ], [t]);

  return (
    <ResponsivePage
      title={t("vatCode:title")}
      maxWidth={config.pageSize.medium}
    >
      <EntityTable
        tableId="vat_codes"
        type="VatCode"
        columns={columns}
        fields={fields}
        createTitle={t("vatCode:create")}
        editTitle={t("vatCode:edit")}
        emptyStateTitle={t("vatCode:emptyStateTitle")}
        emptyStateDescription={t("vatCode:emptyStateDescription")}
        createButton
      />
    </ResponsivePage>
  );
}