import { useCallback, useMemo, useState } from "react";
import { Grid, Button } from "@mui/material";
import { ToggleSurface } from "../../../common/core/Surface";
import { useTranslation } from "react-i18next";
import { useEntity, EntityFieldTypes, makeId, updateFields } from "@emberly/zenith-client";
import EntityEditDialog from "../../../common/inputs/EntityEditDialog";
import SimpleTable from "../../../common/tables/SimpleTable";


export default function ContactPersonsCard() {
  const { t } = useTranslation();
  const { entity, updateEntityField, loading } = useEntity();
  const showLoading = loading && !entity;
  const [dialog, setDialog] = useState(false);

  const columns = useMemo(() => {
    return [
      {
        field: "name",
        headerName: t("common:name"),
        flex: 1,
        minWidth: 100,
      },
      {
        field: "email",
        headerName: t("common:email"),
        flex: 1,
        minWidth: 100,
      },
      {
        field: "phoneNumber",
        headerName: t("entity:contact:phoneNumber"),
        flex: 1,
        minWidth: 100,
      },
      {
        field: "description",
        headerName: t("contactRegister:fieldContactPersonDescription"),
        flex: 1,
        minWidth: 100,
      }
    ]
  }, [t]);

  const fields = useMemo(() => [
    { name: t("common:name"), path: "name", type: EntityFieldTypes.String, required: true },
    { name: t("common:email"), path: "email", type: EntityFieldTypes.String, dataType: "email" },
    { name: t("entity:contact:phoneNumber"), path: "phoneNumber", type: EntityFieldTypes.String },
    { name: t("contactRegister:fieldContactPersonDescription"), path: "description", type: EntityFieldTypes.String, multiline: true }
  ], [t]);

  const onEditCancel = useCallback(() => {
    setDialog(false);
  }, []);

  const onCreateConfirm = useCallback((contact) => {
    setDialog(false);
    updateEntityField("contactPersons", [...entity.contactPersons, { id: makeId(), email: "", phoneNumber: "", description: "", ...contact }]);
  }, [updateEntityField, entity]);

  const onUpdateConfirm = useCallback((contact, values, updatedFields) => {
    setDialog(false);
    const updatedContact = { ...updateFields(contact, updatedFields, values) };
    const list = [...entity.contactPersons];
    const idx = list.findIndex(t => t.id === contact.id);
    list[idx] = updatedContact;
    updateEntityField("contactPersons", list);
  }, [updateEntityField, entity]);

  const onDeleteConfirm = useCallback((contact) => {
    setDialog(false);
    updateEntityField("contactPersons", entity.contactPersons.filter(t => t.id !== contact.id));
  }, [updateEntityField, entity])

  return (
    <ToggleSurface title={t("contact:contactPersonsCard:title")} xs={12}>
      <Grid item container spacing={1}>

        <Grid item xs={12}>
          <SimpleTable
            tableId="contact_persons"
            loading={showLoading}
            columns={columns}
            rows={entity.contactPersons || []}
            fields={fields}
            editTitle={t("contact:contactPersonsCard:edit")}
            onUpdate={onUpdateConfirm}
            onDelete={onDeleteConfirm}
            emptyStateTitle={t("contact:contactPersonsCard:emptyStateTitle")} 
            emptyStateDescription={t("contact:contactPersonsCard:emptyStateDescription")}
          />
        </Grid>

        <Grid item xs={12} container justifyContent="flex-end">
          <Button variant="contained" color="neutral" size="medium" onClick={() => setDialog(true)}>
            {t("contact:contactPersonsCard:add")}
          </Button>
        </Grid>

      </Grid>

      {dialog ? (
        <EntityEditDialog
          createTitle={t("contact:contactPersonsCard:add")}
          fields={fields}
          open={dialog}
          onCancel={onEditCancel}
          onCreate={onCreateConfirm}
        />
      ) : null}

    </ToggleSurface>
  );
}



