import { useState } from "react";
import { makeStyles } from "tss-react/mui";
import { Grid, Typography, Button, Table, TableBody, TableRow, TableCell } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEntity } from "@emberly/zenith-client";
import InvoiceInformationDialog from "../../dialogs/InvoiceInformationDialog";
import { FullTimeView } from "../../../core/Time";

const useStyles = makeStyles()(
  (theme) => ({
    root: {
      width: "100%",
      height: "auto",
      background: theme.palette.background.grey,
      borderRadius: theme.spacing(1),
      padding: theme.spacing(2)
    },
    table: {
      width: "100%",
      "& td": {
        border: "none",
        padding: theme.spacing(1),
        paddingLeft: 0,
        paddingBottom: theme.spacing(0.25),
        paddingTop: theme.spacing(0.25),
      },
    },
  })
);

export default function InvoiceInformationCard() {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { entity } = useEntity();
  const payment = entity.payment
  const invoice = payment?.invoice;

  const [dialog, setDialog] = useState(false);

  return (
    <div className={classes.root}>
      <Grid container spacing={1} alignItems="center">

        <Grid item xs>
          <Table className={classes.table}>
            <TableBody>

              <TableRow>
                <TableCell>
                  <Typography variant="body2" color="textSecondary">{t("order:invoiceInformation:orderDate")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" textAlign="left"><FullTimeView time={invoice?.orderDate} isDate /></Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="body2" color="textSecondary">{t("order:invoiceInformation:reference")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" textAlign="left">{payment?.reference || "-"}</Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="body2" color="textSecondary">{t("order:invoiceInformation:comment")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" textAlign="left">{payment?.comment || "-"}</Typography>
                </TableCell>
              </TableRow>

            </TableBody>
          </Table>
        </Grid>

        <Grid item>
          <Button color="secondary" onClick={() => setDialog(true)}>{t("common:change")}</Button>
        </Grid>

      </Grid>

      {dialog ? (
        <InvoiceInformationDialog onClose={() => setDialog(false)} />
      ) : null}
    </div>
  );
}
