import { makeStyles } from "tss-react/mui";
import { Grid, Typography, Button, Table, TableBody, DialogTitle, DialogContent, DialogActions, FormControl, InputLabel, Select, MenuItem, ListItemText, Divider, Alert, AlertTitle, TableRow, TableCell, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEntity, useEntities, useAuth, useEntityById } from "@emberly/zenith-client";
import { useCallback, useMemo, useState } from "react";
import { useStation } from "../../../../providers/StationProvider";
import { MakeFreeTextOrderLineItem } from "../../../../common/orders";
import { MissionEnumsLists, TaskEnums, TollFeeEnums } from "../../../../common/constants";
import ResponsiveDialog from "../../core/ResponsiveDialog";
import { useMission } from "../../../../providers/MissionProvider";
import { AssumeTaskStateEnum } from "../../../../common/mission";
import axios from "axios";
import TollFeeDisplay from "../panels/TollFeeDisplay";
import { OrderLineItem, OrderLineItemLoading } from "../OrderLines";
import TaskMapSection from "../../map/TaskMapSection";
import { InfoOutlined as InfoIcon } from "@mui/icons-material";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles()(
  (theme) => ({
    table: {
      width: "100%",
      "& td": {
        border: "none",
        padding: theme.spacing(1),
        paddingLeft: 0,
        paddingBottom: "1px",
        paddingTop: "1px",
      }
    },
    compactTable: {
      width: "100%",
      "& td": {
        border: "none",
        padding: theme.spacing(1),
        paddingLeft: 0,
        paddingBottom: "0px",
        paddingTop: "0px",
      }
    },
    card: {
      background: "rgba(0,0,0,0.025)",
      borderRadius: theme.spacing(1),
      padding: theme.spacing(1.5),
      paddingTop: theme.spacing(1),
    },
    compactDivider: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0)
    },
    divider: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(1)
    },
    subdivider: {
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1)
    },
    infoLink: {
      flex: 1,
      maxHeight: "14px",
      textDecoration: "none",
      "& svg": {
        marginBottom: "-1.5px"
      }
    },
  })
);

const findFirstTask = (mission) => {
  const task = mission?.salvageTasks?.[0] || null;
  return task !== null && AssumeTaskStateEnum(task) >= TaskEnums.State.Completed && !!task.execution?.vehicle?.id ? task : null;
}

export default function TollFeeCalculatorDialog(props) {
  const { onClose } = props;
  const { classes } = useStyles();
  const { getAccessTokenSilently } = useAuth();
  const { t } = useTranslation();
  const { pushToList } = useEntity();
  const { mission, routeCollection } = useMission();
  const { priceUnit, orgId } = useStation();


  const { entity: exportSettings, loading: loadingExportSettings } = useEntityById("ExportConfig", orgId, !!orgId, true);
  const { entities: vatCodes } = useEntities("VatCode");
  const { entities: productGroups, loading: loadingProductGroups } = useEntities("ProductGroup");
  const { entities: vehicles, loading: loadingVehicles } = useEntities("ServiceVehicle");

  const [selectedTask, setSelectedTask] = useState(findFirstTask(mission));
  const [tollData, setTollData] = useState(null);
  const [orderLines, setOrderLines] = useState(null);
  const [loading, setLoading] = useState(false);
  const [route, setRoute] = useState(null);
  const [error, setError] = useState(false);
  const [calculated, setCalculated] = useState(false);

  const vehicle = useMemo(() => {
    const v = selectedTask?.execution?.vehicle;
    return !!v ? (vehicles.find((t) => t.id === v.id) || v) : v;
  }, [selectedTask, vehicles]);

  const weight = selectedTask?.route?.overriddenWeight || vehicle?.weight;


  const missingVehicleSettings = useMemo(() => {
    return !loadingVehicles && (!vehicle || !(typeof vehicle.length === "number" && vehicle.length > 0) || !(typeof vehicle.emissionClass === "number" && vehicle.emissionClass > 0) || !(typeof weight === "number" && weight > 0))
  }, [vehicle, loadingVehicles, weight])

  const missingProductGroups = useMemo(() => {
    return !loadingExportSettings && !loadingProductGroups && (!productGroups.find(g => g.id === exportSettings.tollFeeProductGroup?.id) || !productGroups.find(g => g.id === exportSettings.ferryFeeProductGroup?.id))
  }, [exportSettings, productGroups, loadingExportSettings, loadingProductGroups])

  const onSubmit = () => {
    orderLines.forEach(item => pushToList("orderLines", item));
    onClose();
  };

  const onSelectTask = useCallback(async (ev) => {
    const task = mission?.salvageTasks?.find(t => t.taskId === ev.target.value);
    setSelectedTask(task);
    setCalculated(false);
    setRoute(null);
    setError(false);
    setTollData(null);
    setOrderLines(null);
  }, [mission]);

  useMemo(() => {
    const fn = async () => {
      setCalculated(false);
      setRoute(null);
      setError(false);
      setSelectedTask(selectedTask);

      setTollData(null);
      setOrderLines(null);

      if (!!selectedTask) {
        const route = routeCollection.getRoute(`salvageTasks.${selectedTask.index}`, selectedTask.route.overriddenStartLocation);
        route.updateVehicleDataFromTask(selectedTask);
        route.updateFromRawList(selectedTask.route.waypoints);

        await route.loadRouting(true);
        setRoute(route);
      }
    }

    fn();
  }, [routeCollection, selectedTask]);

  const onCalculate = useCallback(async () => {
    if (!!selectedTask && !!route) {
      try {
        setCalculated(true);
        setLoading(true);
        const overrides = selectedTask.route;

        const res = await axios.post("/api/mission/tollfees", {
          coordinates: route.fetchChronologicalRoute(),
          history: selectedTask.execution.history,
          useGeolocationWaypoints: false,
          vehicle: {
            ...vehicle,
            weight: overrides.overriddenWeight || vehicle.weight,
          },
        }, {
          headers: { Authorization: `Bearer ${await getAccessTokenSilently()}` },
        });

        const fees = res.data;
        setTollData(fees || null);
        setOrderLines(makeOrderLines(fees, priceUnit, exportSettings, productGroups, vatCodes));

      } catch (err) {
        console.log(err);
        setError(true);
      } finally {
        setLoading(false);
      }
    }
  }, [getAccessTokenSilently, exportSettings, productGroups, priceUnit, vatCodes, selectedTask, route, vehicle]);


  return (
    <ResponsiveDialog
      open
      onClose={onClose}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        {t("order:tollFeeCalculatorDialog:createTitle")}
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={1.5} direction="row-reverse">

          <Grid item container spacing={1} xs={12} lg={6}>
            <Grid item xs={12}>
              <TaskMapSection
                locked={true}
                route={route}
                routeRevision={1}
              />
            </Grid>
          </Grid>

          <Grid item container spacing={1.5} xs={12} lg={6} alignContent="flex-start">

            <Grid item xs={12}>
              <FormControl fullWidth size="small" variant="filled" >
                <InputLabel>{t("order:tollFeeCalculatorDialog:selectTask")}</InputLabel>
                <Select onChange={onSelectTask} value={selectedTask?.taskId || ""}>
                  {mission?.salvageTasks.map((task, idx) => {
                    const ready = AssumeTaskStateEnum(task) >= TaskEnums.State.Completed && !!task.execution?.vehicle?.id;
                    return (
                      <MenuItem disabled={!ready} key={idx} value={task.taskId}>
                        <ListItemText
                          primary={<>{t(`mission:enums:type:${MissionEnumsLists.Type[mission.details.type]}`)} {t(`mission:enums:targetType:${MissionEnumsLists.TargetType[mission.target.type]}`)} #{mission.number}.{task.number}</>}
                          secondary={!ready ? t("order:tollFeeCalculatorDialog:taskNotReady") : <>{t("order:tollFeeCalculatorDialog:vehicle")}: {task.execution.vehicle?.name || null} - {t(`mission:enums:emissionClass:${MissionEnumsLists.EmissionClass[task.execution.vehicle?.emissionClass || 0]}`)}</>}
                        />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>


            {missingVehicleSettings ? (
              <Grid item xs={12}>
                <Alert
                  severity="warning"
                  action={
                    <NavLink to="/vehicles">
                      <Button>{t("common:add")}</Button>
                    </NavLink>
                  }
                >
                  <AlertTitle>{t("order:tollFeeCalculatorDialog:missingSettings")}</AlertTitle>
                  {t("order:tollFeeCalculatorDialog:missingVehicleSettingsDescription")}
                </Alert>
              </Grid>
            ) : null}

            {missingProductGroups ? (
              <Grid item xs={12}>
                <Alert
                  severity="warning"
                  action={
                    <NavLink to="/station/export">
                      <Button>{t("common:add")}</Button>
                    </NavLink>
                  }
                >
                  <AlertTitle>{t("order:tollFeeCalculatorDialog:missingSettings")}</AlertTitle>
                  {t("order:tollFeeCalculatorDialog:missingProductGroupDescription")}
                </Alert>
              </Grid>
            ) : null}



            <Grid item xs={12}>
              <Box className={classes.card}>
                <Table className={classes.compactTable}>
                  <TableBody>


                    <TableRow>
                      <TableCell>
                        <Typography variant="subtitle1">{t("order:tollFeeCalculatorDialog:vehicle")}</Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography variant="body2" color="textSecondary">{t("serviceVehicle:fieldTitle")}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">{vehicle?.name || vehicle?.title || "-"}</Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography variant="body2" color="textSecondary">{t("serviceVehicle:fieldEmissionClass")}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">{!!vehicle ? t(`mission:enums:emissionClass:${MissionEnumsLists.EmissionClass[vehicle?.emissionClass || 0]}`) : "-"}</Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography variant="body2" color="textSecondary">{t("serviceVehicle:fieldWeight")}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">{weight || "-"}</Typography>
                      </TableCell>
                    </TableRow>


                    <TableRow>
                      <TableCell>
                        <Typography variant="body2" color="textSecondary">{t("serviceVehicle:fieldLength")}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">{vehicle?.length || "-"}</Typography>
                      </TableCell>
                    </TableRow>


                  </TableBody>
                </Table>
              </Box>
            </Grid>

            {
              !calculated ? (
                <Grid item xs={12}>
                  <Button variant="contained" color="secondary" onClick={onCalculate} fullWidth disabled={!selectedTask || !vehicle || missingProductGroups}>{t("order:tollFeeCalculatorDialog:calculate")}</Button>
                </Grid>
              ) : null
            }

            {
              !!loading || !!tollData ? (
                <Grid item xs={12}>
                  <Box className={classes.card}>
                    <Table className={classes.table}>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <Typography variant="subtitle1">{t("order:tollFeeCalculatorDialog:orderlines")}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1" color="textSecondary" textAlign={"right"}>{t("order:orderLines:sumTitle")}</Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>
                            <Divider className={classes.divider} />
                          </TableCell>
                        </TableRow>
                        {loading ? (
                          <>
                            <OrderLineItemLoading />
                          </>
                        ) : orderLines?.map((line, key) => <OrderLineItem key={key} index={key} orderLine={line} disabled />)}

                        <TableRow>
                          <TableCell colSpan={2}>
                            <Divider className={classes.subdivider} />
                          </TableCell>
                        </TableRow>

                      </TableBody>
                    </Table>
                  </Box>
                </Grid>
              ) : null
            }

            {
              !!loading || !!tollData ? (
                <Grid item xs={12}>
                  <Box className={classes.card}>
                    <Table className={classes.table}>
                      <TableBody>

                        <TableRow>
                          <TableCell>
                            <Typography variant="subtitle1" >{t("order:tollFeeCalculatorDialog:basis")}</Typography>
                          </TableCell>
                        </TableRow>


                        <TableRow>
                          <TableCell colSpan={2}>
                            <Divider className={classes.compactDivider} />
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell colSpan={2}>
                            <TollFeeDisplay loading={loading} tollData={tollData} />
                          </TableCell>
                        </TableRow>

                      </TableBody>
                    </Table>
                  </Box>
                </Grid>
              ) : error ? (
                <Grid item xs={12}>
                  <Alert severity="warning">
                    <AlertTitle>{t("order:tollFeeCalculatorDialog:errorTitle")}</AlertTitle>
                    {t("order:tollFeeCalculatorDialog:errorDescription")}
                  </Alert>
                </Grid>
              ) : null
            }

            <Grid item xs={12}>
              <a className={classes.infoLink} href="https://help.getassist.app/nb/articles/10431299-bompengekalkulering-i-assist" target="_blank">
                <Typography variant="body2" color="textSecondary" textAlign="center">
                  <InfoIcon color="inherit" fontSize="inherit" /> {t("order:tollFeeCalculatorDialog:infoLink")}
                </Typography>
              </a>
            </Grid>

          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {t("common:cancel")}
        </Button>
        <Button variant="contained" color="primary" onClick={onSubmit} disabled={!orderLines || orderLines?.length === 0}>
          {t("common:add")}
        </Button>
      </DialogActions>
    </ResponsiveDialog>
  );
}

function makeOrderLines(fees, currency, exportSettings, productGroups, vatCodes) {

  const tollFees = fees.tollFeePoints
    .flatMap(t => t.fees.filter(f => f.feeType !== TollFeeEnums.FeeType.Ferry).flatMap(f => f.feeRebated))
    .filter(f => typeof f === "number")
    .reduce((acc, b) => acc + b, 0);

  const ferryFees = fees.tollFeePoints
    .flatMap(t => t.fees.filter(f => f.feeType === TollFeeEnums.FeeType.Ferry).flatMap(f => f.feeRebated))
    .filter(f => typeof f === "number")
    .reduce((acc, b) => acc + b, 0);

  let result = [];

  if (tollFees > 0) {
    const productGroup = productGroups.find(g => g.id === exportSettings.tollFeeProductGroup?.id);
    result.push(MakeFreeTextOrderLineItem("Bompenger", { value: tollFees.toString(), currency }, "1", "0", productGroup, vatCodes));
  }

  if (ferryFees > 0) {
    const productGroup = productGroups.find(g => g.id === exportSettings.ferryFeeProductGroup?.id);
    result.push(MakeFreeTextOrderLineItem("Ferjebiletter", { value: ferryFees.toString(), currency }, "1", "0", productGroup, vatCodes));
  }


  return result;
}