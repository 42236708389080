import { useTranslation } from "react-i18next";
import ResponsiveSubPage from "../../../common/core/ResponsiveSubPage";
import { ToggleSurface } from "../../../common/core/Surface";
import AssistanceSection from "../../../common/sections/AssistanceSection";
import { useMissionTask } from "../../../../providers/MissionTaskProvider";
import NotFoundPage from "../../error/NotFoundPage";
import config from "../../../../config";

export default function MissionTaskAssistancePage() {
  const { t } = useTranslation();
  const { continueMission, continueMissionText, notFound } = useMissionTask();

  return notFound ? (
    <NotFoundPage backPath="../" />
  ) : (
    <ResponsiveSubPage
      title={t("taskAssistancePage:title")}
      maxWidth={config.pageSize.small}
      backPath="../"
      backButton
      secondaryAction={continueMissionText}
      onSecondaryAction={continueMission}
    >
      <ToggleSurface xs={12}>
        <AssistanceSection showDetails />
      </ToggleSurface>

    </ResponsiveSubPage>
  );
}
