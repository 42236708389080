import { Grid, List, SwipeableDrawer, Collapse, IconButton, Menu, Slide, ListSubheader, Divider, Typography, Popper, Paper } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { useDevice } from "../../../providers/DeviceProvider";
import SidebarListItem, { SidebarButton, SidebarCategoryItem, SidebarLink } from "./SidebarListItem";
import { useCallback, useState, useMemo, useRef, useEffect } from "react";
import SidebarBottomCard from "./SidebarBottomCard";
import { useStation } from "../../../providers/StationProvider";
import { useDriver } from "../../../providers/DriverProvider";
import { useScrollState } from "../../../hooks/useScrollState";
import { useLocation } from "react-router-dom";
import { show } from "@intercom/messenger-js-sdk";
import { KeyboardDoubleArrowLeft as DoubleArrowLeftIcon, KeyboardDoubleArrowRight as DoubleArrowRightIcon, LiveHelpOutlined as LiveHelpIcon, Checklist as ChecklistIcon, DashboardOutlined as DashboardIcon, AccountBalanceOutlined as AccountBalanceIcon, AdminPanelSettingsOutlined as AdminIcon, SettingsOutlined as SettingsIcon, AnalyticsOutlined as AnalyticsIcon, ManageSearchOutlined as ManageSearchIcon } from "@mui/icons-material";

const useStyles = makeStyles()(
  (theme) => ({
    root: {
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: 200,
      "& .MuiDrawer-paper": {
        height: "100%",
        width: "100%",
        overflow: "hidden",
        transition: "width 0.25s"
      }
    },
    grid: {
      overflow: "auto",
      padding: 0,
      flexGrow: 1,
      scrollbarGutter: "stable both-edges",
      "&::-webkit-scrollbar": {
        width: "8px",
        height: "6px",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "4px",
        background: "rgba(155, 155, 155, 0.5)",
      },
    },
    logoContainer: {
      position: "relative",
      width: "100%",
      height: "58px",
      borderBottom: `1px solid ${theme.palette.divider}`,
      marginBottom: theme.spacing(1),
      img: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(1.5),
        height: "24px"
      }
    },
    list: {
      width: "100%",
      padding: 0,
      paddingLeft: "18px"
    },
    compactList: {
      padding: theme.spacing(1),
      paddingBottom: "6px"
    },
    compactListSubHeader: {
      marginBottom: "8px",
      marginTop: "6px",
    },
    compactListDivider: {
      marginBottom: "2px"
    },
    compactButtonIcon: {
      transition: "transform 0.25s"
    },
    compactButtonIconRotated: {
      transform: "scale(-1, 1)"
    },
    compactMenu: {
      zIndex: 999,
      paddingLeft: theme.spacing(3),
      "& .MuiPaper-root": {
        borderRadius: theme.spacing(1),
        padding: "8px",
        minWidth: "200px",
        "& > ul": {
          padding: 0
        }
      }

    }
  })
);

export default function Sidebar(props) {
  const { className, open, setOpen, compactMode, setCompactMode, compactLayoutReady } = props;
  const { classes } = useStyles();
  const { slim, isIos } = useDevice();
  const { t } = useTranslation();
  const { isStationAdmin, isAccountant, isGlobalAdmin, isDriver } = useStation();

  const handleClose = useCallback(() => setOpen(false), []);
  const { activeMissions } = useDriver();
  const { scrollRef, scrollBottom, dispatchEvent } = useScrollState(!slim || open);

  const defaultPages = useMemo(() => [
    isDriver ? { path: "/mymissions", title: t("sidebar:myMissions"), subText: activeMissions.length !== 0 ? activeMissions.length : undefined } : null,
    { path: "/mycashsettlement", title: t("sidebar:myCashSettlement") },
  ], [t, activeMissions, slim, isDriver]);

  const missionPages = useMemo(() => [
    { path: "/dispatch", title: t("sidebar:dispatch") },
    { path: "/activemissions", title: t("sidebar:activeMissions") },
    { path: "/drivers", title: t("sidebar:drivers") },
    { path: "/storage", title: t("sidebar:storage") },
  ], [t]);

  const archivePages = useMemo(() => [
    { path: "/tasks", title: t("sidebar:allTasks") },
    { path: "/missions", title: t("sidebar:allMissions") },
    { path: "/drafts", title: t("sidebar:drafts") },
  ], [t]);

  const accountingPages = useMemo(() => [
    { path: "/invoicing", title: t("sidebar:invoicing") },
    { path: "/cashorders", title: t("sidebar:cashOrders") },
    { path: "/cashsettlements", title: t("sidebar:cashSettlements") },
    { path: "/orders", title: t("sidebar:orders") },
  ], [t]);

  const registerPages = useMemo(() => [
    { path: "/log", title: t("sidebar:activityLog") },
    { path: "/causes", title: t("sidebar:cause") },
    { path: "/vehicles", title: t("sidebar:serviceVehicle") },
    { path: "/tags", title: t("sidebar:missionTag") },
    { path: "/products", title: t("sidebar:product") },
    { path: "/contacts", title: t("sidebar:contact") },
    { path: "/agreements", title: t("sidebar:agreement") },
    { path: "/warehouses", title: t("sidebar:warehouse") },
    { path: "/startlocations", title: t("sidebar:startLocation") },
    { path: "/vatcodes", title: t("sidebar:vatCodes") },
    { path: "/productgroups", title: t("sidebar:productGroups") },
  ], [t]);

  const settingsPages = useMemo(() => [
    isStationAdmin ? { path: "/station/settings", title: t("sidebar:station") } : null,
    isStationAdmin ? { path: "/station/users", title: t("sidebar:users") } : null,
    { path: "/station/export", title: t("sidebar:exportSettings") },
    { path: "/station/integrations", title: t("sidebar:integrations") },
    { path: "/station/import", title: t("sidebar:importPage") },
    isGlobalAdmin ? { path: "/station/globaladmin", title: t("sidebar:globalAdmin") } : null,
  ], [t, isStationAdmin, isGlobalAdmin]);

  const onToggleCompactMode = useCallback(() => {
    setCompactMode(cm => !cm);
  }, []);

  return (
    <SwipeableDrawer
      className={`${classes.root} ${className}`}
      anchor="left"
      open={open || !slim}
      onOpen={() => setOpen(true)}
      onClose={handleClose}
      disableDiscovery={isIos}
      variant={slim ? undefined : "persistent"}
    >
      <Grid container direction="row" className={classes.grid} ref={scrollRef}>
        <Grid item xs={12}>

          <Grid className={classes.logoContainer} container direction="row" justifyContent={compactMode ? "center" : (slim ? "flex-start" : "flex-end")} alignItems="center">
            {
              !compactMode && !compactLayoutReady ? (
                <Grid item xs>
                  <Slide in>
                    <img src="/assets/icons/logo.svg" />
                  </Slide>
                </Grid>
              ) : null
            }
            {
              !slim ? (
                <Grid item>
                  <IconButton onClick={onToggleCompactMode}>
                    <DoubleArrowLeftIcon className={`${classes.compactButtonIcon} ${compactMode ? classes.compactButtonIconRotated : ""}`} />
                  </IconButton>
                </Grid>
              ) : null
            }
          </Grid>


          <SidebarLink
            to="/"
            text={t("sidebar:home")}
            onClick={handleClose}
            icon={<AnalyticsIcon />}
            compactMode={compactMode}
          />


          <Category
            classes={classes}
            handleClose={handleClose}
            title={t("sidebar:myPagesCategory")}
            dispatchEvent={dispatchEvent}
            pages={defaultPages}
            icon={<ChecklistIcon />}
            compactMode={compactMode}
            startOpen
          />

          <Category
            classes={classes}
            handleClose={handleClose}
            title={t("sidebar:operationsCategory")}
            dispatchEvent={dispatchEvent}
            pages={missionPages}
            icon={<DashboardIcon />}
            compactMode={compactMode}
            startOpen
          />

          <Category
            classes={classes}
            handleClose={handleClose}
            title={t("sidebar:archiveCategory")}
            dispatchEvent={dispatchEvent}
            pages={archivePages}
            icon={<ManageSearchIcon />}
            compactMode={compactMode}
            startOpen
          />

          {
            isAccountant ? (
              <Category
                classes={classes}
                handleClose={handleClose}
                dispatchEvent={dispatchEvent}
                title={t("sidebar:accountingCategory")}
                icon={<AccountBalanceIcon />}
                pages={accountingPages}
                startOpen={!isStationAdmin}
                compactMode={compactMode}
              />
            ) : null
          }

          {
            isStationAdmin || isAccountant ? (
              <Category
                classes={classes}
                handleClose={handleClose}
                dispatchEvent={dispatchEvent}
                title={t("sidebar:registersCategory")}
                icon={<AdminIcon />}
                pages={registerPages}
                compactMode={compactMode}
              />
            ) : null
          }

          {
            isStationAdmin || isAccountant || isGlobalAdmin ? (
              <Category
                classes={classes}
                handleClose={handleClose}
                dispatchEvent={dispatchEvent}
                title={t("sidebar:settingsCategory")}
                icon={<SettingsIcon />}
                pages={settingsPages}
                compactMode={compactMode}
              />
            ) : null
          }

          {
            slim ? (
              <SidebarButton compactMode={compactMode} text={t("sidebar:help")} onClick={show} icon={<LiveHelpIcon />} />
            ) : null
          }

        </Grid>
      </Grid>

      <SidebarBottomCard
        shadow={!scrollBottom}
        compactMode={compactMode}
        compactLayoutReady={compactLayoutReady}
      />

    </SwipeableDrawer>
  );
}


function Category(props) {
  const { title, pages, handleClose, startOpen, dispatchEvent, icon, compactMode, classes } = props;
  const [open, setOpen] = useState(!!startOpen);
  const [active, setActive] = useState(false);
  const ref = useRef();
  const { pathname } = useLocation();
  const buttonRef = useRef();

  const onToggle = useCallback((ev) => {
    if (!categoryLocked(ref.current)) {
      setOpen(t => !t);
      setActive(false);
    } else {
      setActive(true);
    }
    dispatchEvent();
  }, [dispatchEvent, compactMode]);

  useEffect(() => {
    if (categoryLocked(ref.current)) {
      setActive(true)
      setOpen(true);
    } else {
      setOpen(false);
      setActive(false);
    }
  }, [ref, pathname]);

  return (
    <>
      <SidebarCategoryItem expanded={open} active={active} text={title} onClick={onToggle} icon={icon} compactMode={compactMode} forwardRef={buttonRef} />
      {!compactMode ? (
        <RegularList
          open={open}
          forwardRef={ref}
          classes={classes}
          pages={pages}
          handleClose={handleClose}
          active={active}
        />
      ) : (
        <CompactList
          title={title}
          buttonRef={buttonRef}
          classes={classes}
          pages={pages}
          forwardRef={ref}
          setActive={setActive}
        />
      )}

    </>
  );
}


function RegularList(props) {
  const { open, forwardRef, classes, pages, handleClose } = props;

  return (
    <Collapse in={open}>
      <List ref={forwardRef} className={classes.list}>
        {pages.map((page, index) => !!page ? <SidebarListItem key={index} to={page.path} text={page.title} onClick={handleClose} subText={page.subText} /> : null)}
      </List>
    </Collapse>
  );
}

const POPPER_OPTIONS = [
  {
    name: "offset",
    options: {
      offset: [0, -8],
    },
  },
];

function CompactList(props) {
  const { buttonRef, classes, pages, title, forwardRef, setActive } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const timerRef = useRef(null);


  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, [])

  useEffect(() => {
    if (!!buttonRef.current) {
      const btn = buttonRef.current;

      const onHide = () => {
        setAnchorEl(null);
      };

      const onClick = (ev) => {
        setAnchorEl(ev.currentTarget);
      };

      const onMouseEnter = (ev) => {
        setAnchorEl(ev.currentTarget);
        clearTimeout(timerRef.current);
      };

      const onMouseLeave = () => {
        timerRef.current = setTimeout(onHide, 50);
      };

      btn.addEventListener("click", onClick);
      btn.addEventListener("mouseenter", onMouseEnter);
      btn.addEventListener("mouseleave", onMouseLeave);
      btn.parentElement.addEventListener("mouseenter", onHide);

      return () => {
        btn.parentElement.removeEventListener("mouseenter", onHide);
        btn.removeEventListener("click", onClick);
        btn.removeEventListener("mouseenter", onMouseEnter);
        btn.removeEventListener("mouseleave", onMouseLeave);
      };
    }
  }, [buttonRef, timerRef]);

  const onPopperRef = useCallback((popper) => {
    if (!!popper) {

      setActive(categoryLocked(popper));

      const leaveListener = () => {
        setAnchorEl(null);
      };

      const onMouseEnter = (ev) => {
        clearTimeout(timerRef.current);
      };

      const onMouseLeave = () => {
        timerRef.current = setTimeout(leaveListener, 500);
      };

      popper.addEventListener("mouseenter", onMouseEnter);
      popper.addEventListener("mouseleave", onMouseLeave);
    }
  }, [timerRef]);


  return (
    <Popper
      className={classes.compactMenu}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      placement="right-start"
      ref={onPopperRef}
      modifiers={POPPER_OPTIONS}
      keepMounted
    >
      <Paper>
        <List className={classes.compactList} ref={forwardRef}>
          <ListSubheader>
            <Typography variant="subtitle2" color="textPrimary" fontWeight="600" className={classes.compactListSubHeader}>
              {title}
            </Typography>
          </ListSubheader>
          <Divider className={classes.compactListDivider} />
          {pages.map((page, index) => !!page ? <SidebarListItem key={index} to={page.path} text={page.title} subText={page.subText} onClick={onClose} compactMode /> : null)}
        </List>
      </Paper>
    </Popper >
  );
}



function categoryLocked(e) {
  if (!e) return false;
  return !!e.querySelector(".active");
}
