import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";
import { Grid, FormControl, InputLabel, Select, MenuItem, Typography, Chip, Table, TableCell, TableRow, TableBody, Divider, ListSubheader } from "@mui/material";
import { ActivityEnums, MissionEnums, MissionEnumsLists } from "../../../../../../common/constants";
import { useNotification } from "../../../../../../providers/NotificationProvider";
import { useMission } from "../../../../../../providers/MissionProvider";
import { GetUserReference } from "../../../../../../common/orders";
import { useEntities, Defaults, useEntityField, useAuth } from "@emberly/zenith-client";
import { MakeStateChange } from "../../../../../../common/mission";

const useStyles = makeStyles()(
  (theme) => ({
    card: {
      borderRadius: theme.spacing(1),
      padding: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      background: theme.palette.background.grey,
      width: "100%",
      height: "auto"
    },
    table: {
      width: "100%",
      wordBreak: "break-word",
      "& td": {
        border: "none",
        padding: theme.spacing(1),
        paddingLeft: 0,
        paddingBottom: theme.spacing(0.25),
        paddingTop: theme.spacing(0.25),
      }
    },
  })
);

export default function DriverDetails(props) {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { path, taskId } = props;
  const { push } = useNotification();
  const { id: missionId, mission, updateMissionField, logMissionEvent } = useMission();
  const { user } = useAuth();

  const missionActive = mission?.state === MissionEnums.State.Created;

  // Handle select driver
  const driver = useEntityField(`${path}.execution.driver`, Defaults.Null);
  const vehicle = useEntityField(`${path}.execution.vehicle`, Defaults.Null);
  const driverAssignedState = useEntityField(`${path}.execution.driverAssignedState`, Defaults.Enum);
  const { entities: drivers } = useEntities("Driver");


  const { driverDoesntExist, driverEntity } = useMemo(() => {
    const d = drivers.find(t => t.id === driver?.value?.id);
    return {
      driverDoesntExist: !!driver.value?.id && !d,
      driverEntity: d
    };
  }, [driver.value, drivers]);

  const onSelectDriver = useCallback(ev => {
    const driverId = ev.target.value;
    const activeDriver = drivers.find(t => t.id === driverId);

    driverAssignedState.onChange(null, !!driverId ? 1 : 0);
    driver.onChange(null, { id: driverId, name: activeDriver?.name || "" });
    vehicle.onChange(null, { id: activeDriver?.vehicle?.id, name: activeDriver?.vehicle?.name });

    if (!!driverId) {
      
      updateMissionField(`${path}.execution.history.assigned`, MakeStateChange(user));
      
      push(driverId, {
        title: t("notification:driverAssignedNotificationTitle"),
        link: `/mymissions/${missionId}/${taskId}?set_organization=${user.org_id}`,
        message: t("notification:driverAssignedNotificationDescription"),
        cancelAction: t("common:close"),
        confirmAction: t("notification:openAction"),
        sender: GetUserReference(user),
      });

      logMissionEvent(ActivityEnums.Type.AssignedTask);

    } else {  
      updateMissionField(`${path}.execution.history.assigned`, null);
    }

    updateMissionField(`${path}.execution.history.responded`, null);
  }, [drivers, driver.onChange, driverAssignedState.onChange, vehicle.onChange, push, taskId, missionId, t, user, updateMissionField, path, logMissionEvent]);

  const textColor = driverAssignedState.value === 0 ? "textSecondary" : "textPrimary";

  const { activeDrivers, inactiveDrivers } = useMemo(() => ({
    activeDrivers: drivers.filter(t => t.active),
    inactiveDrivers: drivers.filter(t => !t.active)
  }), [drivers])

  return (
    <>
      <Grid item xs={12}>
        <FormControl fullWidth size="small" variant="filled" disabled={!missionActive}>
          <InputLabel>{t("mission:cards:tasks:driver:selectDriver")}</InputLabel>
          <Select
            onChange={onSelectDriver}
            value={driver.value?.id || ""}
          >
            <MenuItem value="">{t("mission:cards:tasks:driver:unSelectDriver")}</MenuItem>

            {driverDoesntExist ? (
              <MenuItem value={driver.value.id}>{driver.value.name}</MenuItem>
            ) : null}

            {activeDrivers.length !== 0 || inactiveDrivers.length !== 0 ? <Divider /> : null}

            {activeDrivers.length !== 0 ? <ListSubheader>{t("mission:cards:tasks:driver:driverActive")}</ListSubheader> : null}

            {activeDrivers.map((c, key) => <MenuItem value={c.id} key={key}>{c.name}</MenuItem>)}

            {activeDrivers.length !== 0 && inactiveDrivers.length !== 0 ? <Divider /> : null}

            {inactiveDrivers.length !== 0 ? <ListSubheader>{t("mission:cards:tasks:driver:driverInactive")}</ListSubheader> : null}

            {inactiveDrivers.map((c, key) => <MenuItem value={c.id} key={"_" + key}>{c.name}</MenuItem>)}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <div className={classes.card}>
          <Table className={classes.table}>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography variant="body2" fontWeight="600" color={textColor}>{t("mission:cards:tasks:driver:driverState")}</Typography>
                </TableCell>
                <TableCell>
                  {
                    driverAssignedState.value === 0 ? (
                      <Typography variant="body2" color={textColor}>-</Typography>
                    ) : (
                      <Chip size="small" label={t(`mission:enums:driverAssignedState:${MissionEnumsLists.DriverAssignedState[driverAssignedState.value]}`)} color={driverAssignedState.value === 2 ? "success" : driverAssignedState.value === 3 ? "error" : undefined} />
                    )
                  }
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="body2" fontWeight="600" color={textColor}>{t("common:phoneNumber")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" color={textColor}>{driverEntity?.phoneNumber || "-"}</Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="body2" fontWeight="600" color={textColor}>{t("mission:cards:tasks:driver:activeCar")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" color={textColor}>{vehicle?.value?.name || "-"}</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </Grid>
    </>
  );
}
