import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import EntityTable from "../../common/tables/EntityTable";
import { EntityFieldTypes } from "@emberly/zenith-client";
import ResponsivePage from "../../common/core/ResponsivePage";
import { Grid, Typography } from "@mui/material";
import config from "../../../config";

export default function StartLocationRegister() {
  const { t } = useTranslation();

  const columns = useMemo(() => {
    return [
      {
        field: "name",
        headerName: t("common:name"),
        flex: 1,
        minWidth: 120,
      },
      {
        field: "address",
        headerName: t("entity:location:address"),
        flex: 1,
        valueGetter: params => params.row.location?.address || "-",
        minWidth: 120,
      },
      {
        field: "description",
        headerName: t("startLocationRegister:fieldDescription"),
        flex: 1,
        minWidth: 120,
      },
    ]
  }, [t]);

  const fields = useMemo(() => [
    { name: t("common:name"), path: "name", type: EntityFieldTypes.String, required: true },
    { name: t("startLocationRegister:fieldAddress"), path: "location", type: EntityFieldTypes.Location, required: true },
    { name: t("startLocationRegister:fieldDescription"), path: "description", type: EntityFieldTypes.String },
  ], [t])

  return (
    <ResponsivePage
      title={t("startLocationRegister:title")}
      maxWidth={config.pageSize.medium}
    >
      <Grid item xs={12}>
        <Typography variant="body1" color="textSecondary">{t("startLocationRegister:description")}</Typography>
      </Grid>

      <EntityTable
        tableId="startLocations"
        type="StartLocation"
        columns={columns}
        fields={fields}
        createTitle={t("startLocationRegister:create")}
        editTitle={t("startLocationRegister:edit")}
        emptyStateTitle={t("startLocationRegister:emptyStateTitle")}
        emptyStateDescription={t("startLocationRegister:emptyStateDescription")}
        createButton
      />
    </ResponsivePage>
  );
}