import { useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import EntityTable from "../../common/tables/EntityTable";
import { EntityFieldTypes } from "@emberly/zenith-client";
import { useNavigate } from "react-router-dom";
import ResponsivePage from "../../common/core/ResponsivePage";
import config from "../../../config";

export default function AgreementRegister() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onRowClick = useCallback((params, ev) => {
    navigate(`./${params.id}`, { relative: "path" });
  }, [navigate]);

  const columns = useMemo(() => {
    return [
      {
        field: "name",
        headerName: t("common:name"),
        flex: 1,
        minWidth: 100,
      },
      {
        field: "discounts",
        valueGetter: params => params.row.discountedProducts?.length || 0,
        headerName: t("agreementRegister:fieldDiscounts"),
        flex: 1,
        minWidth: 100,
      },
      {
        field: "description",
        headerName: t("agreementRegister:fieldDescription"),
        flex: 1,
        minWidth: 100,
      },
    ]
  }, [t]);

  const fields = useMemo(() => [
    { name: t("common:name"), path: "name", type: EntityFieldTypes.String, required: true },
    { name: t("agreementRegister:fieldDefaultAccount"), path: "defaultAccount", type: EntityFieldTypes.Search, entityType: "Contact", searchPath: "name", valueGetter: v => ({ id: v.id, name: v.name }) },
    { name: t("agreementRegister:fieldDescription"), path: "description", type: EntityFieldTypes.String, multiline: true },
  ], [t])

  const onCreated = useCallback((entity) => {
    navigate(`./${entity.id}`, { relative: "path" });
  }, [navigate]);

  return (
    <ResponsivePage
      title={t("agreementRegister:title")}
      xs={12}
      xl={8}
      maxWidth={config.pageSize.medium}
    >
      <EntityTable
        tableId="agreements"
        type="Agreement"
        columns={columns}
        fields={fields}
        createTitle={t("agreementRegister:create")}
        editTitle={t("agreementRegister:edit")}
        emptyStateTitle={t("agreementRegister:emptyStateTitle")}
        emptyStateDescription={t("agreementRegister:emptyStateDescription")}
        onRowClick={onRowClick}
        createButton
        onCreated={onCreated}
      />
    </ResponsivePage>
  );
}