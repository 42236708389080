import { useCallback, useMemo } from "react";
import { Grid, Checkbox, FormControlLabel, Collapse, Button, Typography, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import SurfaceSection from "../../../../common/core/SurfaceSection";
import { useEntityField, Defaults } from "@emberly/zenith-client";
import ContactSection from "./ContactSection";
import ContactPersonPicker from "../../../../common/inputs/ContactPersonPicker";
import CollapseGridItem from "../../../../common/core/CollapseGridItem";


export default function ActorsDetailsSection(props) {
  const { locked } = props;
  const { t } = useTranslation();

  const ownerNotRequester = useEntityField("actors.ownerIsNotRequester", Defaults.Boolean);
  const hasContactPerson = useEntityField("actors.hasContactPerson", Defaults.Boolean);
  const ownerContactId = useEntityField("actors.owner.id", Defaults.String);
  const requesterContactId = useEntityField("actors.requester.id", Defaults.String);
  const contactPersonName = useEntityField("actors.contactPerson.name", Defaults.String);
  const contactPersonPhoneNumber = useEntityField("actors.contactPerson.phoneNumber", Defaults.String);
  const contactPersonId = useEntityField("actors.contactPerson.id", Defaults.String);

  const contactPerson = useMemo(() => {
    return { id: contactPersonId.value, name: contactPersonName.value };
  }, [contactPersonId.value, contactPersonName.value])

  const onContactPersonChanged = useCallback((name, contact) => {
    if (!!contact) {
      contactPersonName.onChange(null, contact.name)

      if (!!contact.phoneNumber) {
        contactPersonPhoneNumber.onChange(null, contact.phoneNumber);
      }

      contactPersonId.onChange(null, contact.id);
    } else {
      if (contactPersonId.value && contactPersonName.value !== name) {
        contactPersonId.onChange(null, "");
      }

      contactPersonName.onChange(null, name)
    }
  }, [contactPersonName.onChange, contactPersonName.value, contactPersonId.onChange, contactPersonId.value, contactPersonPhoneNumber.onChange]);

  return (
    <SurfaceSection title={ownerNotRequester.value ? t("mission:cards:actors:actors:owner") : `${t("mission:cards:actors:actors:owner")}/${t("mission:cards:actors:actors:requester")}`}>

      <ContactSection path="actors.owner" locked={locked} />

      <Grid item xs={12}>
        <FormControlLabel
          label={t("mission:cards:actors:actors:ownerNotRequester")}
          control={<Checkbox size="small" checked={ownerNotRequester.value} onChange={ownerNotRequester.onChange} disabled={locked} />}
        />
      </Grid>

      <CollapseGridItem xs={12} in={ownerNotRequester.value}>
        <Grid container spacing={1}>

          <Grid item xs={12}>
            <Typography variant="h6">
              {t("mission:cards:actors:actors:requester")}
            </Typography>
          </Grid>

          {
            !!ownerNotRequester.value ? (
              <ContactSection path="actors.requester" locked={locked} />
            ) : null
          }

        </Grid>
      </CollapseGridItem>

      <Grid item xs={12}>
        <Collapse in={!hasContactPerson.value}>
          <Button variant="outlined" fullWidth onClick={() => hasContactPerson.onChange(null, true)} disabled={locked}>{t("mission:cards:actors:actors:addContactPerson")}</Button>
        </Collapse>

        <Collapse in={hasContactPerson.value}>
          <Grid container spacing={1}>

            <Grid item xs={12}>
              <Typography variant="subtitle1">{t("mission:cards:actors:actors:contactPerson")}</Typography>
            </Grid>

            <Grid item xs={12}>
              <ContactPersonPicker
                label={t("common:name")}
                contactId={ownerNotRequester.value ? requesterContactId.value : ownerContactId.value}
                load={hasContactPerson.value}
                value={contactPerson}
                onChange={onContactPersonChanged}
                disabled={locked}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                disabled={locked}
                label={t("common:phoneNumber")}
                variant="filled"
                fullWidth
                size="small"
                onChange={contactPersonPhoneNumber.onChange}
                value={contactPersonPhoneNumber.value}
              />
            </Grid>

          </Grid>
        </Collapse>

      </Grid>

    </SurfaceSection>
  );
}



