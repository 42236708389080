import { makeStyles } from "tss-react/mui";
import { Grid, TextField, Typography } from "@mui/material";
import { ToggleSurface } from "../../../common/core/Surface";
import { useTranslation } from "react-i18next";
import { useEntityField, Defaults } from "@emberly/zenith-client";
import EntitySearchPicker from "../../../common/inputs/EntitySearchPicker";

const useStyles = makeStyles()(
  (theme) => ({
    info: {
      marginLeft: theme.spacing(1)
    }
  })
);

export default function AgreementInformationCard() {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const name = useEntityField("name", Defaults.String);
  const description = useEntityField("description", Defaults.String);
  const defaultAccount = useEntityField("defaultAccount", Defaults.Null);

  return (
    <ToggleSurface title={t("agreement:agreementInformationCard:title")} md={12} lg={6}>
      <Grid item container spacing={1}>

        <Grid item xs={12}>
          <TextField
            label={t("common:name")}
            value={name.value}
            onChange={name.onChange}
            variant="filled"
            fullWidth
            size="small"
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label={t("agreement:agreementInformationCard:description")}
            value={description.value}
            onChange={description.onChange}
            variant="filled"
            fullWidth
            multiline
            size="small"
          />
        </Grid>

        <Grid item xs={12}>
          <EntitySearchPicker
            type="Contact"
            path="name"
            label={t("agreement:agreementInformationCard:defaultAccount")}
            value={defaultAccount.value}
            onChange={v => defaultAccount.onChange(null, v === null ? null : { id: v.id, name: v.name })}
          />
          <Typography color="textSecondary" variant="caption" className={classes.info}>
            {t("agreement:agreementInformationCard:defaultAccountInfo")}
          </Typography>
        </Grid>

      </Grid>
    </ToggleSurface>
  );
}

