import { Grid, Button, MenuItem, FormControl, Dialog, DialogTitle, DialogContent, DialogActions, InputLabel, Select, TextField, Box, Chip } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useUserManagement } from "../../../../providers/UserManagementProvider";
import { makeStyles } from "tss-react/mui";
import { arrayEquals } from "../../../../common/helpers";
import { useAuth } from "@emberly/zenith-client";
import { RoleIds } from "../../../../common/constants";

const useStyles = makeStyles()(
  (theme) => ({
    selected: {
      fontWeight: 500
    }
  })
);


export default function UserEditDialog(props) {
  const { task, user } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();

  const { user: self } = useAuth();

  const [userName, setUserName] = useState(user.name);

  const userRoleMap = user.roles.map(t => t.id);
  const [userRoles, setUserRoles] = useState(userRoleMap);
  const { roles } = useUserManagement();

  const isSelf = self?.sub === user?.userId;
  const hasChange = user.name !== userName || !arrayEquals(userRoleMap, userRoles);

  return (
    <Dialog
      open
      onClose={() => task.throw("cancel")}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        {t("userManagement:editDialog:title")}
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={1}>

          <Grid item xs={12}>
            <TextField
              label={t("common:name")}
              onChange={ev => setUserName(ev.target.value)}
              value={userName}
              variant="filled"
              fullWidth
              size="small"
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl variant="filled" fullWidth>
              <InputLabel id="chip-label">{t("userManagement:editDialog:roles")}</InputLabel>
              <Select
                labelId="chip-label"
                multiple
                value={userRoles}
                onChange={(ev) => setUserRoles(ev.target.value)}
                renderValue={(roleIds) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {roleIds.map((value) => (
                      <Chip key={value} label={t(`roles:${roles.find(r => r.id === value)?.name}`)} />
                    ))}
                  </Box>
                )}
              >
                {roles.map((role, key) => (
                  <MenuItem
                    key={key}
                    value={role.id}
                    className={userRoles.includes(role.id) ? classes.selected : undefined}
                    disabled={isSelf && role.id === RoleIds.StationAdmin && userRoles.includes(role.id)}
                  >
                    {t(`roles:${role.name}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

          </Grid>

        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => task.throw("cancel")}>
          {t("common:cancel")}
        </Button>
        <Button
          variant="contained" color="primary"
          onClick={() => task.complete({
            id: user.userId,
            name: userName !== user.name ? userName : null,
            roles: arrayEquals(userRoles, user.roles.map(t => t.id)) ? null : userRoles,
          })}
          disabled={!hasChange}
        >
          {t("common:saveChanges")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
