import { useCallback, useMemo } from "react";
import { makeStyles } from "tss-react/mui";
import { Chip, Grid, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useEntities, Compare, Location } from "@emberly/zenith-client";
import { MissionEnums, MissionEnumsLists, TaskEnums } from "../../../common/constants";
import moment from "moment/moment";
import SimpleTable from "../../common/tables/SimpleTable";
import { ToggleSurface } from "../../common/core/Surface";
import { MapMissionTasks, GetTaskDriverState } from "../../../common/mission";
import ResponsivePage from "../../common/core/ResponsivePage";
import { useStation } from "../../../providers/StationProvider";
import { CompactTimeView, TimeView } from "../../common/core/Time";
import config from "../../../config";
import TaskStateChip from "../../common/display/TaskStateChip";

const useStyles = makeStyles()(
  (theme) => ({
    root: {
      padding: `${theme.spacing(2)} ${theme.spacing(6)}`,
      paddingBottom: "200px",
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start"
    },
    grid: {
      maxWidth: "1600px"
    },
    driverName: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      marginLeft: "2px"
    },
    noDriverName: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      marginLeft: "2px",
      color: theme.palette.text.secondary
    },
    squareChip: {
      borderRadius: theme.spacing(1),
      height: "auto",
      minWidth: "96px",
      lineHeight: "16px",
      paddingTop: "8px",
      paddingBottom: "8px",
      justifyContent: "flex-start",
      "& .time": {
        fontSize: "12px",
        opacity: 0.75
      }
    },
    rejectedRow: {
      background: theme.palette.error.background,
      "&:hover": {
        background: `${theme.palette.error.backgroundFocus} !important`
      }
    },
    pendingWarnRow: {
      background: theme.palette.warning.selected,
      "&:hover": {
        background: `${theme.palette.warning.focusVisible} !important`
      }
    },
    vehicleText: {
      color: theme.palette.text.secondary
    }
  })
);

const query = {
  name: "active_or_completed_filter",
  or: [
    {
      path: "state",
      comparer: Compare.EQ,
      value: MissionEnums.State.Created
    },
    {
      and: [
        {
          path: "state",
          comparer: Compare.EQ,
          value: MissionEnums.State.Completed
        },
        {
          path: "lastModified",
          comparer: Compare.GTE,
          value: moment().startOf("day").toDate()
        },
      ]
    }
  ]
};

export default function DispatchPage() {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { createMissionDraft } = useStation();
  const { entities, loading } = useEntities("Mission", query);
  const showLoading = loading && entities.length === 0;

  const { activeTasks, completedTasks } = useMemo(() => {
    const tasks = entities.flatMap(t => MapMissionTasks(t));
    const startOfDay = moment().startOf("day").toDate();

    return {
      activeTasks: tasks.filter(t => t.state < TaskEnums.State.Completed && t.missionState === MissionEnums.State.Created).reverse(),
      completedTasks: tasks.filter(t => t.state >= TaskEnums.State.Completed && moment(t.execution.history?.completed?.created).toDate() >= startOfDay).reverse()
    };
  }, [entities]);

  const onRowClick = useCallback((params, ev) => {
    navigate(`./${params.id}`, { relative: "path" });
  }, [navigate]);

  const columns = useMemo(() => {
    return [
      {
        field: "number",
        headerName: t("dispatch:table:taskNumber"),
        valueGetter: params => `${params.row.missionNumber}.${params.row.number}`,
        flex: 0,
      },
      {
        field: "created",
        headerName: t("common:tables:created"),
        type: "dateTime",
        valueGetter: params => params.row.execution.history.created?.created || null,
        renderCell: (params) => <TimeView time={params.value} />,
        flex: 0,
        minWidth: 160,
      },
      {
        field: "type",
        headerName: t("common:tables:type"),
        flex: 1,
        minWidth: 120,
        type: "singleSelect",
        valueGetter: (params) => params.row.missionDetails.type,
        valueFormatter: (params) => t(`mission:enums:type:${MissionEnumsLists.Type[params.value]}`),
        valueOptions: MissionEnumsLists.Type.map((_, value) => ({
          value,
          label: t(`mission:enums:type:${MissionEnumsLists.Type[value]}`)
        }))
      },
      {
        field: "cause",
        headerName: t("common:tables:cause"),
        valueGetter: params => params.row.missionDetails?.cause?.name || "-",
        flex: 1,
        minWidth: 100,
      },
      {
        field: "details.tags",
        headerName: t("common:tables:tags"),
        valueGetter: params => params.row.missionDetails?.tags?.map(t => t.name).join() || "",
        flex: 1,
        minWidth: 100,
        renderCell: params => (
          <Grid container spacing={0.5}>
            {params.row.missionDetails?.tags?.map((tag, key) => <Grid item key={key}><Chip label={tag.name} size="small" /></Grid>)}
          </Grid>
        )
      },
      {
        field: "object",
        headerName: t("common:tables:object"),
        valueGetter: (params) => {
          const type = params.row.missionTarget.type;
          const details = type === 1 ? params.row.missionTarget.carDetails : (type === 2 ? params.row.missionTarget.boatDetails : null);
          return details ? `${details.make} ${details.model}` : "-";
        },
        minWidth: 100,
        flex: 1
      },
      {
        field: "registration",
        headerName: t("common:tables:registration"),
        valueGetter: params => {
          const type = params.row.missionTarget.type;
          const details = type === 1 ? params.row.missionTarget.carDetails : (type === 2 ? params.row.missionTarget.boatDetails : null);
          return details?.registration || "-"
        },
        minWidth: 100,
        flex: 1
      },
      {
        field: "place",
        headerName: t("common:tables:place"),
        valueGetter: params => params.row.route.waypoints[0]?.place || "-",
        flex: 1,
        minWidth: 100,
      },
      {
        field: "address",
        headerName: t("common:tables:address"),
        valueGetter: params => Location.ShortAddress(params.row.route.waypoints[0]?.title, params.row.route.waypoints[0]?.address),
        minWidth: 100,
        flex: 1
      },
      {
        field: "description",
        headerName: t("common:tables:locationDescription"),
        valueFormatter: (params) => params.value.length > 40 ? `${params.value.substring(0, 39)} ...` : params.value,
        minWidth: 100,
        flex: 1
      },
      {
        field: "transportTo",
        headerName: t("common:tables:transportTo"),
        valueGetter: params => {
          const list = params.row.route.waypoints;

          if (!list || list.length < 2) {
            return "-";
          } else {
            const location = list[list.length - 1];
            return Location.ShortAddress(location.title, location.address);
          }
        },
        renderCell: params => params.row.resolvedAtLocation ? <Chip label={t("dispatch:table:resolvedAtLocation")} size="small" /> : params.value,
        minWidth: 100,
        flex: 1
      },
      {
        field: "details",
        headerName: t("common:tables:details"),
        valueGetter: params => params.row.missionDetails.description || "-",
        valueFormatter: params => params.value.length > 40 ? params.value.substring(0, 38) + "..." : params.value,
        flex: 1,
        minWidth: 140
      },
      {
        field: "driver",
        flex: 1,
        minWidth: 160,
        headerName: t("common:tables:driver"),
        valueGetter: params => params.row.execution?.driver?.name || "",
        renderCell: params => {
          const execution = params.row.execution;
          const name = execution?.driver?.name || "";
          const noDriver = !execution?.driver?.id;

          return noDriver ? (
            <span>-</span>
          ) : (
            <Stack>
              <span>{name}</span>
              <span className={classes.vehicleText}>{execution?.vehicle?.name || "-"}</span>
            </Stack>
          );
        }
      },
      {
        field: "eta",
        headerName: t("common:tables:eta"),
        type: "dateTime",
        valueGetter: params => params.row.execution.estimatedArrival,
        renderCell: (params) => <TimeView time={params.value} />,
        flex: 1,
        minWidth: 100,
      },
      {
        field: "state",
        headerName: t("common:tables:state"),
        type: "singleSelect",
        valueOptions: [
          t(`task:enums:driverAssignedState:Unknown`),
          t(`task:enums:driverAssignedState:Accepted`),
          t(`task:enums:driverAssignedState:Rejected`),
          t(`task:enums:state:Created`),
          t(`task:enums:state:Assigned`),
          t(`task:enums:state:Started`),
          t(`task:enums:state:Arrived`),
          t(`task:enums:state:Loaded`),
          t(`task:enums:state:Completed`),
          t(`task:enums:state:Acknowledged`),
        ],
        valueGetter: (params) => GetTaskDriverState(params.row.execution.driverAssignedState, params.row.state, t, false),
        renderCell: params => {
          return (
            <TaskStateChip
              state={params.row.state}
              driverAssignedState={params.row.execution.driverAssignedState}
              className={classes.squareChip}
              label={(
                <>
                  {params.value}<br />
                  {!!params.row.stateData?.lastModified ? <span className="time"><CompactTimeView time={params.row.stateData?.lastModified} /></span> : null}
                </>
              )}
            />
          );
        },
        flex: 1,
        minWidth: 220,
      }
    ]
  }, [t, classes]);

  const onGetRowClassName = useCallback((params) => {
    const driverAssignedState = params.row.execution?.driverAssignedState;

    if (driverAssignedState === 1) {
      const history = params.row.execution.history;

      if (!!history?.assigned?.lastModified) {
        const minutesSince = moment().diff(moment(history.assigned.lastModified), "minutes");
        if (minutesSince > 10) return classes.pendingWarnRow;
      }
    }

    return driverAssignedState === 3 ? classes.rejectedRow : undefined;
  }, [classes]);

  return (
    <ResponsivePage
      title={t("dispatch:title")}
      secondaryAction={t("dispatch:createMission")}
      onSecondaryAction={createMissionDraft}
      maxWidth={config.pageSize.full}
    >

      <ToggleSurface
        xs={12}
        title={t("dispatch:activeTasks:title")}
      >
        <SimpleTable
          tableId="active_tasks"
          loading={showLoading}
          rows={activeTasks}
          columns={columns}
          onRowClick={onRowClick}
          emptyStateTitle={t("dispatch:activeTasks:emptyStateTitle")}
          emptyStateDescription={t("dispatch:activeTasks:emptyStateDescription")}
          getRowClassName={onGetRowClassName}
        />
      </ToggleSurface>

      <ToggleSurface
        xs={12}
        title={t("dispatch:completedTasks:title")}
      >
        <SimpleTable
          tableId="completed_tasks"
          loading={showLoading}
          rows={completedTasks}
          columns={columns}
          onRowClick={onRowClick}
          emptyStateTitle={t("dispatch:completedTasks:emptyStateTitle")}
          emptyStateDescription={t("dispatch:completedTasks:emptyStateDescription")}
        />
      </ToggleSurface>

    </ResponsivePage>
  );
}