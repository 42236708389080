import { makeStyles } from "tss-react/mui";
import { Collapse, Grid } from "@mui/material";
import { useEffect, useRef, useState } from "react";


const useStyles = makeStyles()(
  () => ({
    root: {
      transition: "padding-top 0.25s"
    },
    hidden: {
      paddingTop: "0 !important"
    }
  })
);


export default function CollapseGridItem(props) {
  const { xs, sm, md, lg, xl, xxl } = props;
  const { classes } = useStyles();

  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl} className={`${classes.root} ${!props.in ? classes.hidden : ""}`}>
      <Collapse in={props.in}>
        {props.children}
      </Collapse>
    </Grid>
  );
}



export function CollapseGridWithoutFocusItem(props) {
  const { xs, sm, md, lg, xl, xxl } = props;
  const { classes } = useStyles();
  const ref = useRef(null);
  const [open, setOpen] = useState(props.in);

  useEffect(() => {

    if (props.in) {
      setOpen(true);
    } else {
      const input = ref.current?.querySelector("input");

      if (input?.matches(":focus")) {

        const fn = () => {
          setOpen(false);
        }


        input.addEventListener("blur", fn);

        return () => {
          input?.removeEventListener("blur", fn);
        }
      } else {
        setOpen(false);
      }
    }

  }, [props.in, ref]);





  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl} className={`${classes.root} ${!open ? classes.hidden : ""}`} ref={ref}>
      <Collapse in={open}>
        {props.children}
      </Collapse>
    </Grid>
  );
}