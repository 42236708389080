import { Grid, Button, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { useTranslation } from "react-i18next";
import Receipt from "../Receipt";
import useReceipt from "../../../../hooks/useReceipt";
import ResponsiveDialog from "../../core/ResponsiveDialog";

export default function ReceiptDialog(props) {
  const { onClose } = props;
  const { t } = useTranslation();

  const { sharePDF, downloadPDF } = useReceipt();

  return (
    <ResponsiveDialog
      open
      onClose={onClose}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>
        {t("order:receiptDialog:title")}
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>

          <Grid item xs={12}>
            <Receipt />
          </Grid>

        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {t("common:close")}
        </Button>
        <Button onClick={downloadPDF} variant="outlined">
          {t("common:download")}
        </Button>
        <Button variant="contained" color="primary" onClick={sharePDF}>
          {t("order:receiptDialog:send")}
        </Button>
      </DialogActions>
    </ResponsiveDialog>
  );
}


