import { useCallback } from "react";
import { makeStyles } from "tss-react/mui";
import { Grid, Tabs, Tab } from "@mui/material";
import { useParams } from "react-router-dom";
import { EntityContextProvider } from "@emberly/zenith-client";
import { useTranslation } from "react-i18next";
import ContactInformationCard from "./cards/ContactInformationCard";
import CustomerInformationCard from "./cards/CustomerInformationCard";
import ContactPersonsCard from "./cards/ContactPersonsCard";
import ContactMissionsCard from "./cards/ContactMissionsCard";
import { useNavigate } from "react-router-dom";
import ContactLayout from "./common/ContactLayout";
import ContactOrdersCard from "./cards/ContactOrdersCard";

const useStyles = makeStyles()(
  (theme) => ({
    root: {
      padding: `${theme.spacing(2)} ${theme.spacing(6)}`,
      paddingBottom: "200px",
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start"
    },
    grid: {
      maxWidth: "1200px"
    },
    tabs: {
      marginBottom: theme.spacing(3),
      borderBottom: `1px solid ${theme.palette.divider}`
    },
  })
);

export default function ContactPage() {
  const { classes } = useStyles();
  const { contactId, tabId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onTabChange = useCallback((_, val) => navigate(`../${val}`, { relative: "path", replace: true }), [navigate]);

  return (
    <EntityContextProvider id={contactId} type="Contact">
      <ContactLayout>

        <Grid item xs={12}>
          <Tabs className={classes.tabs} value={tabId} onChange={onTabChange} variant="scrollable" scrollButtons="auto">
            <Tab value="details" label={t("contact:tabs:contactInformationTab")} />
            <Tab value="missions" label={t("contact:tabs:missionsTab")} />
            <Tab value="orders" label={t("contact:tabs:ordersTab")} />
          </Tabs>
        </Grid>

        {
          tabId === "missions" ? (
            <ContactMissionsCard contactId={contactId} />
          ) : tabId === "details" ? (
            <>
              <ContactInformationCard />
              <CustomerInformationCard />
              <ContactPersonsCard />
            </>
          ) : tabId === "orders" ? (
            <ContactOrdersCard contactId={contactId} />
          ) : null
        }

      </ContactLayout>
    </EntityContextProvider>
  );
}
