import { createContext, useContext, useMemo, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { Box, Collapse, Grid, Slide, Stack } from "@mui/material";
import Scrollbar from "./Scrollbar";
import LargeHeader from "../navigation/LargeHeader";
import { useDevice } from "../../../providers/DeviceProvider";
import { useScrollState } from "../../../hooks/useScrollState";
import { Helmet } from "react-helmet-async";

const useStyles = makeStyles()(
  (theme) => ({
    wideRoot: {
      padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
      paddingBottom: "200px",
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
      "@media (max-width: 1200px)": {
        padding: theme.spacing(2),
      }
    },
    scrollHeader: {
      position: "absolute",
      top: 0,
      width: "100%",
      zIndex: 101,
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
      background: "white",
      boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.10)",
    },
    slimRoot: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      overflow: "hidden",
      background: theme.palette.background.default
    },
    slimScrollbar: {
      padding: theme.spacing(1),
      paddingTop: theme.spacing(2)
    },
    slimGrid: {
      overflow: "hidden"
    }
  })
);


export const ResponsivePageContext = createContext();

export const useResponsivePage = () => useContext(ResponsivePageContext);

export default function ResponsivePage(props) {
  const { maxWidth, className, safeTitle, title, caption, stateTag, stateTagColor, primaryAction, secondaryAction, onPrimaryAction, onSecondaryAction, menuItems, backButton, backPath, xs, sm, md, lg, xl, xxl, headerWidget, footerWidget, scrollRef, secondaryActionComponent, secondaryActionIcon, widget } = props;
  const { classes } = useStyles();
  const { slim } = useDevice();
  const [actionButton, setActionButton] = useState(null);

  const { scrollRef: scrollRefInternal, scrollTop } = useScrollState(slim && !scrollRef || !slim, slim ? 0 : 100);

  const maxWidthStyle = useMemo(() => {
    return !maxWidth ? undefined : { maxWidth };
  }, [maxWidth]);

  return (
    <ResponsivePageContext.Provider
      value={{
        setActionButton
      }}
    >
      {
        !!title && typeof title === "string" ? (
          <Helmet>
            <title>Assist - {title}</title>
          </Helmet>
        ) : !!safeTitle ? (
          <Helmet>
            <title>Assist - {safeTitle}</title>
          </Helmet>
        ) : null
      }
      {
        slim ? (
          <Stack className={`${classes.slimRoot} ${className || ""}`} direction="column">
            <LargeHeader
              shadow={!scrollTop}
              widget={widget}
              title={title}
              caption={caption}
              stateTag={stateTag}
              stateTagColor={stateTagColor}
              primaryAction={primaryAction}
              secondaryAction={secondaryAction}
              secondaryActionComponent={secondaryActionComponent}
              secondaryActionIcon={secondaryActionIcon}
              onPrimaryAction={onPrimaryAction}
              onSecondaryAction={onSecondaryAction}
              menuItems={menuItems}
              backButton={backButton}
              backPath={backPath}
              actionButton={actionButton}
            />
            {headerWidget}
            <Scrollbar className={classes.slimScrollbar} scrollRef={scrollRef || scrollRefInternal}>
              <Grid container direction="row" spacing={2} className={classes.slimGrid}>
                {props.children}
              </Grid>
            </Scrollbar>
            {footerWidget}
          </Stack>
        ) : (
          <>

            {
              backButton ? (
                <Slide in={!scrollTop}>
                  <Box className={classes.scrollHeader}>

                    <LargeHeader
                      compact
                      widget={widget}
                      title={title}
                      caption={caption}
                      stateTag={stateTag}
                      stateTagColor={stateTagColor}
                      primaryAction={primaryAction}
                      secondaryAction={secondaryAction}
                      onPrimaryAction={onPrimaryAction}
                      onSecondaryAction={onSecondaryAction}
                      secondaryActionComponent={secondaryActionComponent}
                      secondaryActionIcon={secondaryActionIcon}
                      menuItems={menuItems}
                      backButton={backButton}
                      backPath={backPath}
                      actionButton={actionButton}
                    />
                  </Box>
                </Slide>
              ) : null
            }

            <Scrollbar className={`${classes.wideRoot} ${className || ""}`} scrollRef={scrollRefInternal}>
              <Grid
                container
                direction="row"
                spacing={3}
                alignItems="flex-start"
                justifyContent="flex-start"
                item
                style={maxWidthStyle}
              >


                <LargeHeader
                  widget={widget}
                  title={title}
                  caption={caption}
                  stateTag={stateTag}
                  stateTagColor={stateTagColor}
                  primaryAction={primaryAction}
                  secondaryAction={secondaryAction}
                  onPrimaryAction={onPrimaryAction}
                  onSecondaryAction={onSecondaryAction}
                  secondaryActionComponent={secondaryActionComponent}
                  secondaryActionIcon={secondaryActionIcon}
                  menuItems={menuItems}
                  backButton={backButton}
                  backPath={backPath}
                  actionButton={actionButton}
                />

                {headerWidget}

                {props.children}

              </Grid>
            </Scrollbar>
          </>
        )
      }
    </ResponsivePageContext.Provider>
  );
}