import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Error as ErrorIcon, CheckCircle as CheckIcon } from "@mui/icons-material"
import { useMission } from "../../../../providers/MissionProvider";
import { AllTasksCompleted, AssumeStorageState } from "../../../../common/mission";
import { GetMissionOrderState } from "../../../../common/orders";
import { MissionEnums, OrderEnums } from "../../../../common/constants";

export default function CompleteMissionDialog(props) {
  const { onClose, onComplete } = props;
  const { t } = useTranslation();
  const { orders, mission } = useMission();

  const allTasksCompleted = AllTasksCompleted(mission);
  const missionOrderState = GetMissionOrderState(orders);
  const storageState = AssumeStorageState(mission);

  const hasTasks = mission.salvageTasks.length !== 0;
  const hasStorage = storageState !== MissionEnums.StorageState.None;
  const hasOrders = orders.length !== 0;
  const isReady = allTasksCompleted && missionOrderState === OrderEnums.MissionOrderState.Closed && storageState !== MissionEnums.StorageState.Stored;

  return (
    <Dialog
      open
      onClose={onClose}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        {isReady ? t("mission:closeDialog:readyTitle") : t("mission:closeDialog:warnTitle")}
      </DialogTitle>
      <DialogContent>

        <Grid container direction="row" spacing={2}>

          <Grid item xs={12}>
            <Typography variant="body1">
              {isReady ? t("mission:closeDialog:readyDescription") : t("mission:closeDialog:warnDescription")}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <List>

              {
                hasTasks ? (
                  allTasksCompleted ? (
                    <DialogItem
                      text={t("mission:closeDialog:tasksReady")}
                      ready
                    />
                  ) : (
                    <DialogItem
                      text={t("mission:closeDialog:tasksWarn")}
                      description={t("mission:closeDialog:tasksWarnDescription")}
                    />
                  )
                ) : null
              }

              {
                hasStorage ? (
                  storageState === MissionEnums.StorageState.Delivered ? (
                    <DialogItem
                      text={t("mission:closeDialog:storageReady")}
                      ready
                    />
                  ) : (
                    <DialogItem
                      text={t("mission:closeDialog:storageWarn")}
                      description={t("mission:closeDialog:storageWarnDescription")}
                    />
                  )
                ) : null
              }


              {
                hasOrders ? (
                  missionOrderState === OrderEnums.MissionOrderState.Closed ? (
                    <DialogItem
                      text={t("mission:closeDialog:ordersReady")}
                      ready
                    />
                  ) : (
                    <DialogItem
                      text={t("mission:closeDialog:ordersWarn")}
                      description={t("mission:closeDialog:ordersWarnDescription")}
                    />
                  )
                ) : (
                  <DialogItem
                    text={t("mission:closeDialog:ordersError")}
                    description={t("mission:closeDialog:ordersErrorDescription")}
                  />
                )
              }

            </List>

          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>
          {t("common:close")}
        </Button>
        <Button onClick={onComplete} variant="contained" color={isReady ? "primary" : "warning"} >
          {isReady ? t("mission:closeDialog:complete") : t("mission:closeDialog:completeWarn")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function DialogItem(props) {
  const { ready, text, description } = props;

  return (
    <ListItem sx={{ paddingLeft: "0", alignItems: "flex-start" }}>
      <ListItemIcon sx={{ minWidth: "0px", paddingRight: "8px", paddingTop: ready ? "4px" : "6px" }}>
        {ready ? <CheckIcon color="success" /> : <ErrorIcon color="warning" />}
      </ListItemIcon>
      <ListItemText
        primary={<span style={{ fontWeight: 500 }} >{text}</span>}
        secondary={description}
      />
    </ListItem>
  );
}