import { useState } from "react";
import { makeStyles } from "tss-react/mui";
import { Grid, Typography, IconButton, Button, Divider, Table, TableBody, TableRow, TableCell, Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Edit as EditIcon, Add as AddIcon } from "@mui/icons-material";
import ReferencedOrderLineItemDialog from "./dialogs/ReferencedOrderLineItemDialog";
import FreeTextOrderLineItemDialog from "./dialogs/FreeTextOrderLineItemDialog";
import { OrderEnumsLists } from "../../../common/constants";
import { CalcTotal, FixNumber } from "../../../common/orders";
import { useOrder } from "../../../providers/OrderProvider";
import TollFeeCalculatorDialog from "./dialogs/TollFeeCalculatorDialog";

const useStyles = makeStyles()(
  (theme) => ({
    root: {
      width: "100%",
      height: "auto",
      background: theme.palette.background.grey,
      borderRadius: theme.spacing(1),
      padding: theme.spacing(2)
    },
    table: {
      width: "100%",
      "& td": {
        border: "none",
        padding: theme.spacing(1),
        paddingLeft: 0,
        paddingBottom: theme.spacing(0.25),
        paddingTop: theme.spacing(0.25),
      },
      "& tr > :last-child": {
        textAlign: "right",
        justifyContent: "flex-end",
        paddingRight: 0

      }
    },
    emptyState: {
      margin: theme.spacing(1)
    },
    button: {
      marginTop: theme.spacing(0.75)
    }
  })
);

export default function OrderLines(props) {
  const { disabled } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();

  const { order, open } = useOrder();
  const { orderLines } = order;
  const [referencedOrderLineItemDialog, setReferencedOrderLineItemDialog] = useState(false);
  const [freeTextOrderLineItemDialog, setFreeTextOrderLineItemDialog] = useState(false);
  const [tollFeeCalculatorDialog, setTollFeeCalculatorDialog] = useState(false);

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>

        <Grid item xs>
          <Typography variant="body1" color="textSecondary">{t("order:orderLines:productTitle")}</Typography>
        </Grid>

        <Grid item>
          <Typography variant="body1" color="textSecondary">{t("order:orderLines:sumTitle")}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          {orderLines.length !== 0 ? (
            <Table className={classes.table}>
              <TableBody>
                {orderLines.map((t, index) => (<OrderLineItem key={index} index={index} orderLine={t} open={open} disabled={disabled} />))}
              </TableBody>
            </Table>
          ) : (
            <Typography textAlign="center" variant="body2" color="textSecondary" className={classes.emptyState}>
              {t("order:orderLines:empty")}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Button variant="contained" color="neutral" startIcon={<AddIcon />} fullWidth onClick={() => setReferencedOrderLineItemDialog(true)} className={classes.button} disabled={!open || disabled}>
            {t("order:orderLines:addReferencedProduct")}
          </Button>
        </Grid>


        <Grid item xs={6}>
          <Button variant="contained" color="neutral" startIcon={<AddIcon />} fullWidth onClick={() => setFreeTextOrderLineItemDialog(true)}  disabled={!open || disabled}>
            {t("order:orderLines:addTextProduct")}
          </Button>
        </Grid>

        <Grid item xs={6}>
          <Button variant="contained" color="neutral" startIcon={<AddIcon />} fullWidth onClick={() => setTollFeeCalculatorDialog(true)}  disabled={!open || disabled}>
            {t("order:orderLines:addTollFees")}
          </Button>
        </Grid>

      </Grid>

      {referencedOrderLineItemDialog ? (
        <ReferencedOrderLineItemDialog onClose={() => setReferencedOrderLineItemDialog(false)} />
      ) : null}

      {freeTextOrderLineItemDialog ? (
        <FreeTextOrderLineItemDialog onClose={() => setFreeTextOrderLineItemDialog(false)} />
      ) : null}

      {tollFeeCalculatorDialog ? (
        <TollFeeCalculatorDialog onClose={() => setTollFeeCalculatorDialog(false)} />
      ) : null}

    </div>
  );
}


export function OrderLineItem(props) {
  const { orderLine, index, open, disabled } = props;
  const { t } = useTranslation();
  const currency = t(`order:enums:currencyShortHand:${OrderEnumsLists.Currency[orderLine.price.currency]}`);
  const [edit, setEdit] = useState(false);

  const discounted = !!orderLine.discount && orderLine.discount !== "0";

  return (
    <TableRow>
      <TableCell>
        <Typography variant="body1">{orderLine.name}</Typography>
        <Typography variant="body2" color="textSecondary">
          {FixNumber(orderLine.quantity)} {t("order:orderLineItem:unit")} {t("order:orderLineItem:at")} {FixNumber(CalcTotal(orderLine.price, "1", orderLine.discount))} {currency}
          <br />{discounted ? `${orderLine.discount}% ${t("order:orderLineItem:discount")}` : ""}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant="body1" textAlign="right">{FixNumber(CalcTotal(orderLine.price, orderLine.quantity, orderLine.discount))} {currency}</Typography>
        <Typography variant="body2" color="textSecondary" textAlign="right">{orderLine.productGroup.vatCode.rate}% {t("order:orderLineItem:vat")}</Typography>
      </TableCell>

      {
        !disabled ? (
          <TableCell>
            <IconButton size="small" onClick={() => setEdit(true)} disabled={!open || disabled}>
              <EditIcon fontSize="small" />
            </IconButton>
          </TableCell>
        ) : null
      }

      {edit ? (
        orderLine.isReferenced ? (
          <ReferencedOrderLineItemDialog
            onClose={() => setEdit(false)}
            orderLine={orderLine}
            index={index}
            edit
          />
        ) : (
          <FreeTextOrderLineItemDialog
            onClose={() => setEdit(false)}
            orderLine={orderLine}
            index={index}
            edit
          />
        )
      ) : null}

    </TableRow>
  );
}

export function OrderLineItemLoading() {
  const { t } = useTranslation();

  return (
    <TableRow>
      <TableCell>
        <Skeleton variant="text" width="100%" height="50px" />
      </TableCell>

      <TableCell>
        <Skeleton variant="text" width="100%" height="50px"  />
      </TableCell>

    </TableRow>
  );

}