import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, TextField, Typography, FormControl, FormControlLabel, FormGroup, FormLabel, Checkbox, Select, MenuItem, InputLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IntegrationLibrary } from "./library";
import { IntegrationEnumsLists } from "../../../../common/constants";
import { makeStyles } from "tss-react/mui";
import { useState } from "react";
import { Task } from "@emberly/zenith-client";
import ConfirmDialog from "../../../common/inputs/ConfirmDialog";
import IntegrationMappings from "./IntegrationMappings";

const useStyles = makeStyles()(
  (theme) => ({
    divider: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1)
    },
    link: {
      color: "inherit"
    },
    settings: {
      marginTop: theme.spacing(0.5),
      "& label": {
        marginTop: theme.spacing(1),
      }
    },
  })
);

export default function IntegrationDialog(props) {
  const { serviceType, task, entity, existing } = props;
  const library = IntegrationLibrary.get(serviceType);
  const { t } = useTranslation();
  const { classes } = useStyles();

  const [friendlyName, setFriendlyName] = useState(entity?.friendlyName || library.name || "");
  const [apiKey, setApiKey] = useState(entity?.apiKey || "");
  const [userKey, setUserKey] = useState(entity?.userKey || "");
  const [settings, setSettings] = useState(entity?.settings || library.settings || {});
  const [mappings, setMappings] = useState(entity?.mappings || {});
  const [region, setRegion] = useState(entity?.region || "");

  const hasRequiredData = !!friendlyName && (!library.hasApiKey || !!apiKey) && (!library.hasUserKey || !!userKey) && (!library.regions || !!region);

  const onComplete = () => task.complete({
    serviceName: library.name,
    category: library.category,
    serviceType,
    friendlyName,
    apiKey,
    userKey,
    settings,
    mappings,
  });

  const [deleteAction, setDeleteAction] = useState(null);

  const onDelete = async () => {
    try {
      const t = new Task();
      setDeleteAction(t)
      await t.wait();
      task.throw("delete");
    } catch {
      setDeleteAction(null);
    }
  }

  return (
    <Dialog
      open
      onClose={() => task.throw("cancelled")}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        {!existing ? t("common:add") : t("common:edit")} {library.name}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t(`integrations:library:${IntegrationEnumsLists.Service[serviceType]}:description`)}
        </DialogContentText>

        <Grid container spacing={2}>

          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>

          {
            !!library.regions ? (
              <Grid item xs={12}>
                <FormControl fullWidth size="small" variant="filled" required>
                  <InputLabel>{t(`integrations:library:${IntegrationEnumsLists.Service[serviceType]}:region`)}</InputLabel>
                  <Select onChange={ev => setRegion(ev.target.value)} value={region}>
                    {library.regions.map((r, idx) => <MenuItem selected={r === region} value={r} key={idx}>{t(`integrations:library:${IntegrationEnumsLists.Service[serviceType]}:regions:${r}`)}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
            ) : null
          }

          <Grid item xs={12}>
            <TextField
              label={t("integrationDialog:inputs:name")}
              variant="filled"
              fullWidth
              size="small"
              value={friendlyName}
              onChange={(ev) => setFriendlyName(ev.target.value)}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">{t("integrationDialog:apiKeys")}</Typography>
            <Typography variant="body2" color="textSecondary">
              {t(`integrations:library:${IntegrationEnumsLists.Service[serviceType]}:instructions`)}&nbsp;
              {!!library.instructionsLink ? <a href={library.instructionsLink} className={classes.link} target="__blank">{t(`integrations:library:${IntegrationEnumsLists.Service[serviceType]}:instructionsLink`)}</a> : null}
            </Typography>
          </Grid>

          {
            library.hasApiKey ? (
              <Grid item xs={12}>
                <TextField
                  label={t(`integrations:library:${IntegrationEnumsLists.Service[serviceType]}:apiKey`)}
                  variant="filled"
                  fullWidth
                  size="small"
                  value={apiKey}
                  onChange={(ev) => setApiKey(ev.target.value)}
                  required
                />
              </Grid>
            ) : null
          }

          {
            library.hasUserKey ? (
              <Grid item xs={12}>
                <TextField
                  label={t(`integrations:library:${IntegrationEnumsLists.Service[serviceType]}:userKey`)}
                  variant="filled"
                  fullWidth
                  size="small"
                  value={userKey}
                  onChange={(ev) => setUserKey(ev.target.value)}
                  required
                />
              </Grid>
            ) : null
          }

          {
            !!library.settings ? (
              <Grid item xs={12}>
                <FormControl className={classes.settings}>
                  <FormLabel>{t("integrationDialog:settings")}</FormLabel>
                  <FormGroup>
                    {Object.keys(library.settings).map((flag, i) => (
                      <FormControlLabel
                        key={i}
                        label={t(`integrations:settings:${flag}`)}
                        control={
                          <Checkbox
                            checked={settings[flag] || false}
                            onChange={(ev) => {
                              setSettings(s => ({ ...s, [flag]: ev.target.checked }));
                            }}
                          />
                        }
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </Grid>
            ) : null
          }

          {
            !!library.mappings ? (
              <IntegrationMappings
                library={library}
                mappings={mappings}
                setMappings={setMappings}
              />
            ) : null
          }


        </Grid>

      </DialogContent>
      <DialogActions>

        {
          !!existing ? (
            <Button onClick={onDelete} color="error">
              {t("common:remove")}
            </Button>
          ) : null
        }

        <Button onClick={() => task.throw("cancelled")}>
          {t("common:cancel")}
        </Button>

        {
          !!existing ? (
            <Button variant="contained" color="primary" onClick={onComplete} autoFocus disabled={!hasRequiredData}>
              {t("common:saveChanges")}
            </Button>
          ) : (
            <Button variant="contained" color="primary" onClick={onComplete} autoFocus disabled={!hasRequiredData}>
              {t("integrationDialog:create")}
            </Button>
          )
        }
      </DialogActions>

      <ConfirmDialog
        title={t("integrationDialog:deleteTitle")}
        description={t("integrationDialog:deleteDescription")}
        task={deleteAction}
        confirmText={t("integrationDialog:deleteAction")}
      />

    </Dialog >
  );
}