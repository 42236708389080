import { MapIcons } from "./constants";
import { GeoJsonPoint } from "./maphelpers";
import Route from "./Route";


export default class LiveLocationUpdater {

  constructor(map, conf = {}) {
    this._map = map;
    this._conf = conf;

    this._layer_name = "live-location-layer";
    this._source_name = "live-location-source";
    this._timeout = 15;

    this._data = [];
    this._timer = null;
  }

  setup() {

    // create map data source
    this._map.addSource(this._source_name, {
      type: "geojson",
      data: Route.EmptyGeoJson()
    });

    // create map layer
    

    this._map.addModel("car-model", `${window.location.origin}/assets/models/truck/source/model.gltf`);

    this._map.addLayer({
      id: `${this._layer_name}_model`,
      type: "model",
      source: this._source_name,
      minzoom: 14,
      layout: {
        "model-id": "car-model",
      },
      paint: {
        "model-type": "location-indicator",
        "model-rotation": ["get", "rotation"],
        "model-scale": [7, 7, 7]
      }
    });


    this._map.addLayer({
      type: "symbol",
      source: this._source_name,
      id: `${this._layer_name}_text`,
      layout: {
        "text-allow-overlap": true,
        "text-ignore-placement": true,
        "text-field": ["get", "title"],
        "text-font": [
          "Open Sans Semibold",
          "Arial Unicode MS Bold"
        ],
        "text-size": 10,
        "text-offset": [0, -1.25],
        "text-anchor": "bottom"
      },
      paint: {
        "text-halo-color": "#fff",
        "text-halo-width": 3
      }
    });

    this._map.addLayer({
      type: "symbol",
      source: this._source_name,
      id: `${this._layer_name}_icons`,
      maxzoom: 14,
      layout: {
        "icon-allow-overlap": true,
        "icon-ignore-placement": true,
        "icon-size": 0.12,
        "icon-image": ["get", "icon"],
        "icon-rotate": ["get", "heading"],
        "icon-rotation-alignment": "map",
      },
      paint: {
        "icon-color": ["get", "color"],
      }
    });


    this._timer = setInterval(() => this.render(), 15000);

    // setup hover and click listeners
    return this;
  }

  destroy() {
    clearInterval(this._timer);
  }

  render() {

    let collection = this._data
      .filter( loc =>
        LiveLocationUpdater.MinutesSince(loc.lastReportedLocation.timestamp) < this._timeout
      ).map(loc => GeoJsonPoint(
      [
        loc.lastReportedLocation.coords.longitude,
        loc.lastReportedLocation.coords.latitude,
      ],
      this.makePointProperties(loc)
    ));

    // n. set data at source
    this._map?.getSource(this._source_name)?.setData(Route.GeoJson(collection));
  }


  update(data) {
    if (!this._map || this._map.forceKilled) return;

    this._data = data.filter(
      t =>
        t.active &&
        !!t.driver?.id &&
        !!t.lastReportedLocation?.coords?.latitude &&
        !!t.lastReportedLocation?.coords?.longitude
    );

    this.render();
  }


  makePointProperties(loc) {
    const moving = typeof loc.lastReportedLocation.coords.heading === "number";
    const heading = loc.lastReportedLocation.coords.heading || 0;
    const minutesSince = Math.floor(LiveLocationUpdater.MinutesSince(loc.lastReportedLocation.timestamp));

    return {
      title: `${(loc.title || "").replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '')},\n${(loc.lastReportedLocation?.user?.name || "").replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '')}${!!minutesSince ? `\n(~${minutesSince}m)` : ""}`,
      color: "#334",
      icon: moving ? MapIcons.Navigation.Arrow : MapIcons.Navigation.Car,
      heading: heading,
      rotation: [0, 0, heading + 180]
    }
  }


  static MinutesSince(ts) {
    if (typeof ts !== "number") return 99999;
    return (Date.now() - ts) / 60000.0;
  }

}