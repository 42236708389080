import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useEntityById, useEntities, useMutateState, Compare, useAuth, Task, StatusCode } from "@emberly/zenith-client";
import { MissionEnums, TaskEnums } from "../common/constants";
import { useStation } from "./StationProvider";
import { MapMissionTasks } from "../common/mission";
import moment from "moment/moment";
import { GetUserReference } from "../common/orders";
import LiveLocationUpdater from "../common/LiveLocationUpdater";
import { GeolocationDistance } from "../common/maphelpers";

const ACTIVE_VEHICLES_QUERY = {
  path: "active",
  comparer: Compare.EQ,
  value: true,
  name: "active_vehicles_filter"
};

export const DriverContext = createContext();
export const useDriver = () => useContext(DriverContext);

export const getDriverId = (user) => {
  return !!user ? `${user.org_id}|${user.sub}` : null;
};

let PRINTED_ERROR = false;

export const DriverProvider = (props) => {
  const { user } = useAuth();
  const { isDriver } = useStation();
  const driverId = getDriverId(user);
  const { entity: driver, updateEntityField } = useEntityById("Driver", driverId, !!user && isDriver, !!user && isDriver);
  const { entities: vehicles, updateEntity: updateVehicleEntity } = useEntities("ServiceVehicle", ACTIVE_VEHICLES_QUERY, isDriver);
  const [geolocation, setGeolocation] = useState(null);
  const hasVehicle = !!driver?.vehicle?.id;
  
  const vehicleDetails = useMemo(() => {
    const data = vehicles?.find(t => t.id === driver?.vehicle?.id);

    return !!data ? {
      id: data.id,
      name: data.title,
      width: data.width,
      height: data.height,
      weight: data.weight,
      length: data.length,
      emissionClass: data.emissionClass,
      fuel: data.fuel
    } : null;
  }, [driver, vehicles]);

  const myMissionsQuery = useMemo(() => {
    if (!driverId) return null;

    return {
      name: "my_missions_filter",
      and: [
        {
          path: "state",
          value: MissionEnums.State.Created,
          comparer: Compare.EQ
        },
        {
          path: "salvageTasks.execution.driver.id",
          value: driverId,
          comparer: Compare.EQ
        }
      ]
    };
  }, [driverId]);

  const moveVehicle = useMoveVehicleToDriver();

  const setVehicle = useCallback((vehicle) => {
    moveVehicle(driver, vehicle);
  }, [driver, moveVehicle]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(setGeolocation);
    }
  }, []);


  const updateVehicle = useCallback((data, fields) => {
    if (!!driver?.vehicle?.id) {
      updateVehicleEntity({ id: driver.vehicle.id, ...data }, fields);
    }
  }, [driver, updateVehicleEntity]);

  const getGeolocation = useCallback((resourceId = null) => {
    try {
      const t = new Task();

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(loc => {

          const geoloc = {
            timestamp: Date.now(),
            user: GetUserReference(user),
            resourceId,
            coords: {
              latitude: loc.coords?.latitude,
              longitude: loc.coords?.longitude,
              accuracy: loc.coords?.accuracy || 50,
              heading: loc.coords?.heading,
              speed: loc.coords?.speed,
            }
          };

          setGeolocation(geoloc);
          t.complete(geoloc);
        }, err => {
          if (!PRINTED_ERROR) {
            PRINTED_ERROR = true;
            console.log(err);
          }
          t.complete(null);
        }, { maximumAge: 15000, timeout: 10000, enableHighAccuracy: true });
      } else {
        setTimeout(() => t.complete(null), 1);
      }

      return t.wait();
    } catch (err) {
      console.log(err);
    }
  }, [user]);

  const setActive = useCallback((active) => updateEntityField("active", active), [updateEntityField]);

  const { entities: myMissions, updateEntity } = useEntities("Mission", myMissionsQuery, !!myMissionsQuery && isDriver);

  const { activeMissions, completedMissions } = useMemo(() => {
    const tasks = myMissions.flatMap(mission => MapMissionTasks(mission)).filter(t => t.execution?.driver?.id === driverId);
    const startOfDay = moment().startOf("day").toDate();
    return {
      activeMissions: tasks.filter(t => t.state < TaskEnums.State.Completed || t.execution.driverAssignedState === TaskEnums.DriverAssignedState.Pending),
      completedMissions: tasks.filter(t => t.state >= TaskEnums.State.Completed && moment(t.execution.history?.completed?.created).toDate() >= startOfDay)
    };
  }, [myMissions, driverId]);


  useEffect(() => {
    if (!!driver?.active && isDriver && hasVehicle) {

      let prev = null;
      const fn = async () => {
        try {
          const task = activeMissions.find(t => t.state >= TaskEnums.State.Started && t.state < TaskEnums.State.Completed);
          const gloc = await getGeolocation(task?.id);
          
          if (!!gloc && gloc?.coords?.accuracy < 500 && (!prev || GeolocationDistance(prev, gloc) > 10 || LiveLocationUpdater.MinutesSince(prev.timestamp) > 0.65)) {
            updateVehicle({ lastReportedLocation: gloc }, ["lastReportedLocation"])
            prev = gloc;
          }

        } catch (err) {
          console.log(err);
        }
      };

      const timer = setInterval(fn, 12000);
      fn();

      return () => {
        clearInterval(timer);
      };
    }
  }, [getGeolocation, activeMissions, updateVehicle, hasVehicle, driver, isDriver]);

  return (
    <DriverContext.Provider
      value={{
        id: driverId,
        driver,
        vehicles,
        active: driver?.active || false,
        loading: !driver,
        setActive,
        vehicle: driver?.vehicle,
        vehicleDetails,
        hasVehicle,
        setVehicle,
        activeMissions,
        completedMissions,
        updateEntity,
        geolocation,
        getGeolocation,
        updateVehicle
      }}
    >
      {props.children}
    </DriverContext.Provider>
  );
};



export function useMoveVehicleToDriver() {
  const mutateState = useMutateState();

  return useCallback(async (driver, vehicle) => {
    let driverUpdates = [];
    let vehicleUpdates = [];

    // should set vehicle to the given driver
    if (!!driver) {
      driverUpdates.push({
        data: {
          id: driver.id,
          vehicle: {
            name: vehicle?.title || "",
            id: vehicle?.id || ""
          }
        },
        fields: ["vehicle"]
      });
    }

    // should set driver to the current vehicle
    if (!!vehicle) {
      vehicleUpdates.push({
        data: {
          id: vehicle.id,
          driver: {
            id: driver?.id || "",
            name: driver?.name || ""
          }
        },
        fields: ["driver"]
      });
    }

    // should unset the driver from the drivers old vehicle
    if (!!driver?.vehicle?.id && driver.vehicle.id !== vehicle?.id) {
      vehicleUpdates.push({
        data: {
          id: driver.vehicle.id,
          driver: { id: "", name: "" }
        },
        fields: ["driver"]
      });
    }

    // should unset the vehicle on the vehicles previous driver
    if (!!vehicle?.driver?.id && vehicle.driver.id !== driver.id) {
      driverUpdates.push({
        data: {
          id: vehicle.driver.id,
          vehicle: { id: "", name: "" }
        },
        fields: ["vehicle"]
      });
    }

    // construct final mutations object
    let mutations = [];

    if (driverUpdates.length !== 0) {
      mutations.push({
        type: "Driver",
        update: driverUpdates
      })
    }

    if (vehicleUpdates.length !== 0) {
      mutations.push({
        type: "ServiceVehicle",
        update: vehicleUpdates
      })
    }

    const response = await mutateState(mutations);

    return response?.statusCode === StatusCode.Success;

  }, [mutateState]);
}