import { makeStyles } from "tss-react/mui";
import { Grid, Tabs, Tab } from "@mui/material";
import { useTranslation } from "react-i18next";
import ResponsivePage from "../../common/core/ResponsivePage";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback } from "react";
import OpenInvoiceOrdersSection from "./subpages/OpenInvoiceOrdersSection";
import ReadyForInvoicingSection from "./subpages/ReadyForInvoicingSection";
import InvoiceJournalsSection from "./subpages/InvoiceJournalsSection";
import InvoiceJournalEntriesSection from "./subpages/InvoiceJournalEntriesSection";
import config from "../../../config";

const useStyles = makeStyles()(
  (theme) => ({
    tabs: {
      marginBottom: theme.spacing(3),
      borderBottom: `1px solid ${theme.palette.divider}`
    },
  })
);

export default function InvoicingPage() {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { tabId } = useParams();

  const navigate = useNavigate();
  const onTabChange = useCallback((_, val) => navigate(!!tabId ? `../${val}` : `./${val}`, { relative: "path", replace: true }), [navigate, tabId]);

  return (
    <ResponsivePage
      title={t("invoicing:title")}
      maxWidth={config.pageSize.large}
    >

      <Grid item xs={12}>
        <Tabs className={classes.tabs} value={tabId || "open"} onChange={onTabChange} variant="scrollable" scrollButtons="auto">
          <Tab value="open" label={t("invoicing:openOrdersTab")} />
          <Tab value="ready" label={t("invoicing:readyForJournalingTab")} />
          <Tab value="journals" label={t("invoicing:journalsTab")} />
          <Tab value="journalentries" label={t("invoicing:journalEntriesTab")} />
        </Tabs>
      </Grid>

      {
        !tabId || tabId === "open" ? (
          <OpenInvoiceOrdersSection />
        ) : tabId === "ready" ? (
          <ReadyForInvoicingSection />
        ) : tabId === "journals" ? (
          <InvoiceJournalsSection />
        ) : tabId === "journalentries" ? (
          <InvoiceJournalEntriesSection />
        ) : null
      }

    </ResponsivePage>
  );
}

