import { makeStyles } from "tss-react/mui";
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, LinearProgress } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { useStation } from "../../../../providers/StationProvider";
import mapboxgl from "mapbox-gl";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles()(
  (theme) => ({
    pickerActions: {
      padding: theme.spacing(3)
    },
    mapContainer: {
      position: "relative",
      height: "70vh",
      maxHeight: "700px",
      width: "100%",
      marginTop: theme.spacing(2),
      "& .map": {
        width: `calc(100% + ${theme.spacing(6)})`,
        height: "100%",
        marginLeft: theme.spacing(-3),
        marginRight: theme.spacing(-3)
      }
    },
  })
);

export default function DesktopLocationPicker(props) {
  const { value, onConfirm, onCancel, loading } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();

  const { station } = useStation();
  const mapRef = useRef(null);
  const [pickerCoordinates, setPickerCoordinates] = useState(null);

  const onMapMounted = useCallback((component) => {
    try {
      if (!component) return;

      component.innerHTML = "";
      const hasExistingCoords = !!(value?.coordinates || station?.location?.coordinates);

      const map = new mapboxgl.Map({
        container: component, // container ID
        style: "mapbox://styles/mapbox/streets-v12", // style URL
        center: hasExistingCoords ? (value?.coordinates || station?.location?.coordinates) : [8.2, 60], // starting position [lng, lat]
        zoom: hasExistingCoords ? 12 : 6, // starting zoom
        attributionControl: false
      });

      mapRef.current = map;
      let marker = new mapboxgl.Marker();
      let markerAdded = false;
      setPickerCoordinates(null);

      map.on("load", () => {
        // Add features
        if (!!value?.coordinates) {
          marker.setLngLat(value.coordinates);

          if (!markerAdded) {
            markerAdded = true;
            marker.addTo(map);
          }
        }
      });

      map.on("click", (ev) => {
        const { lat, lng } = ev.lngLat;

        setPickerCoordinates([lng, lat]);
        marker.setLngLat([lng, lat]);

        if (!markerAdded) {
          markerAdded = true;
          marker.addTo(map);
        }
      });
    } catch (err) {
      console.log(err);
    }
  }, [value, station]);

  const onPickerClose = useCallback(() => {
    mapRef.current?.remove();
    onCancel();
  }, [onCancel]);

  const onPickerConfirm = useCallback(() => {
    mapRef.current?.remove();
    onConfirm(pickerCoordinates);
  }, [pickerCoordinates, onConfirm]);

  return (
    <Dialog
      open
      fullWidth
      maxWidth="md"
      onClose={onPickerClose}
    >
      {loading ? (
        <LinearProgress variant="indeterminate" />
      ) : null}

      <DialogTitle>
        {t("location:pickerTitle")}
      </DialogTitle>

      <DialogContent>

        <DialogContentText>
          {t("location:pickerDescription")}
        </DialogContentText>

        <div className={classes.mapContainer}>
          <div ref={onMapMounted} className="map"></div>
        </div>

      </DialogContent>

      <DialogActions className={classes.pickerActions}>
        <Button onClick={onPickerClose} disabled={loading} size="large">{t("common:cancel")}</Button>
        <Button disabled={!pickerCoordinates || loading} onClick={onPickerConfirm} variant="contained" color="primary" size="large">{t("common:confirm")}</Button>
      </DialogActions>

    </Dialog>
  );
}


