import { makeStyles } from "tss-react/mui";
import { Grid, Typography, TextField, Button, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { ToggleSurface } from "../../common/core/Surface";
import { useTranslation } from "react-i18next";
import { useStation } from "../../../providers/StationProvider";
import { useCallback, useEffect, useState } from "react";
import ResponsivePage from "../../common/core/ResponsivePage";
import { useEntityById, useEntities } from "@emberly/zenith-client";
import config from "../../../config";

const useStyles = makeStyles()(
  (theme) => ({
    description: {
      marginBottom: theme.spacing(2),
      width: "100%"
    },
  })
);

export default function ExportSettingsPage() {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const { orgId } = useStation();

  const { entity: exportSettings, updateEntity } = useEntityById("ExportConfig", orgId, !!orgId, true);
  const { entities: productGroups } = useEntities("ProductGroup");


  const [working, setWorking] = useState(false);
  const [mobilePaymentAccount, setMobilePaymentAccount] = useState("");
  const [cashAccount, setCashAccount] = useState("");
  const [cardAccount, setCardAccount] = useState("");

  const [vatTransferId, setVatTransferId] = useState("");
  const [deductibleId, setDeductibleId] = useState("");
  
  const [tollFeeId, setTollFeeId] = useState("");
  const [ferryFeeId, setFerryFeeId] = useState("");

  useEffect(() => {
    if (!!exportSettings) {
      setMobilePaymentAccount(t => t || exportSettings.mobilePaymentAccount || "");
      setCashAccount(t => t || exportSettings.cashAccount || "");
      setCardAccount(t => t || exportSettings.cardAccount || "");
      setVatTransferId(t => t || exportSettings?.vatTransferProductGroup?.id || "");
      setDeductibleId(t => t || exportSettings?.deductibleProductGroup?.id || "");
      setTollFeeId(t => t || exportSettings?.tollFeeProductGroup?.id || "");
      setFerryFeeId(t => t || exportSettings?.ferryFeeProductGroup?.id || "");
    }
  }, [exportSettings]);

  const onSave = useCallback(async () => {
    try {
      setWorking(true);
      const vatGroup = productGroups.find(t => t.id === vatTransferId);
      const deductibleGroup = productGroups.find(t => t.id === deductibleId);
      const tollFeeGroup = productGroups.find(t => t.id === tollFeeId);
      const ferryFeeGroup = productGroups.find(t => t.id === ferryFeeId);
      
      await updateEntity({
        id: orgId,
        mobilePaymentAccount,
        cashAccount,
        cardAccount,
        deductibleProductGroup: !!deductibleGroup ? { id: deductibleId, name: `${deductibleGroup.code}. ${deductibleGroup.name}` } : null,
        vatTransferProductGroup: !!vatGroup ? { id: vatTransferId, name: `${vatGroup.code}. ${vatGroup.name}` } : null,
        tollFeeProductGroup: !!tollFeeGroup ? { id: tollFeeId, name: `${tollFeeGroup.code}. ${tollFeeGroup.name}` } : null,
        ferryFeeProductGroup: !!ferryFeeGroup ? { id: ferryFeeId, name: `${ferryFeeGroup.code}. ${ferryFeeGroup.name}` } : null,
      }, ["mobilePaymentAccount", "cashAccount", "cardAccount", "deductibleProductGroup", "vatTransferProductGroup", "tollFeeProductGroup", "ferryFeeProductGroup"]);

    } catch (err) {
      console.log(err);
    } finally {
      setWorking(false)
    }

  }, [updateEntity, mobilePaymentAccount, cashAccount, cardAccount, vatTransferId, deductibleId, tollFeeId, ferryFeeId, productGroups, orgId]);

  const changesExport = (
    (!!mobilePaymentAccount && mobilePaymentAccount !== exportSettings?.mobilePaymentAccount) ||
    (!!cashAccount && cashAccount !== exportSettings?.cashAccount) ||
    (!!cardAccount && cardAccount !== exportSettings?.cardAccount) ||
    (!!vatTransferId && vatTransferId !== exportSettings?.vatTransferProductGroup?.id) || 
    (!!deductibleId && deductibleId !== exportSettings?.deductibleProductGroup?.id)
  );

  const changesTollFees = (
    (!!tollFeeId && tollFeeId !== exportSettings?.tollFeeProductGroup?.id) ||
    (!!ferryFeeId && ferryFeeId !== exportSettings?.ferryFeeProductGroup?.id) 
  );

  return (
    <ResponsivePage
      maxWidth={config.pageSize.small}
      title={t("exportSettings:header")}
    >
      <ToggleSurface
        title={
          <>
            <Typography variant="h5">{t("exportSettings:journalTitle")}</Typography>
            <Typography variant="body2" color="textSecondary" className={classes.description}>{t("exportSettings:journalDescription")}</Typography>
          </>
        }
      >

        <Grid item container spacing={2}>

          <Grid item xs={12}>
            <Typography variant="subtitle1">{t("exportSettings:accountsTitle")}</Typography>
            <Typography variant="body2" color="textSecondary">{t("exportSettings:accountsDescription")}</Typography>
          </Grid>

          <Grid item xs={12} xl={4}>
            <TextField
              label={t("exportSettings:mobilePaymentAccount")}
              onChange={ev => setMobilePaymentAccount(ev.target.value)}
              value={mobilePaymentAccount}
              variant="filled"
              fullWidth
              size="small"
            />
          </Grid>

          <Grid item xs={12} xl={4}>
            <TextField
              label={t("exportSettings:cashAccount")}
              onChange={ev => setCashAccount(ev.target.value)}
              value={cashAccount}
              variant="filled"
              fullWidth
              size="small"
            />
          </Grid>

          <Grid item xs={12} xl={4}>
            <TextField
              label={t("exportSettings:cardAccount")}
              onChange={ev => setCardAccount(ev.target.value)}
              value={cardAccount}
              variant="filled"
              fullWidth
              size="small"
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle1">{t("exportSettings:transferTitle")}</Typography>
            <Typography variant="body2" color="textSecondary">{t("exportSettings:transferDescription")}</Typography>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth size="small" variant="filled" disabled={productGroups.length === 0}>
              <InputLabel>{t("exportSettings:deductibleSelect")}</InputLabel>
              <Select onChange={ev => setDeductibleId(ev.target.value)} value={deductibleId}>
                {productGroups.map((group, idx) => <MenuItem selected={group.id === deductibleId} value={group.id} key={idx}>{group.code}. {group.name}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth size="small" variant="filled" disabled={productGroups.length === 0}>
              <InputLabel>{t("exportSettings:vatSelect")}</InputLabel>
              <Select onChange={ev => setVatTransferId(ev.target.value)} value={vatTransferId}>
                {productGroups.map((group, idx) => <MenuItem selected={group.id === deductibleId} value={group.id} key={idx}>{group.code}. {group.name}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>


          <Grid item xs={12}>
            <Button variant="contained" color="primary" disabled={!changesExport || working} onClick={onSave}>
              {t("common:saveChanges")}
            </Button>
          </Grid>
        </Grid>

      </ToggleSurface>


      <ToggleSurface
        title={
          <>
            <Typography variant="h5">{t("exportSettings:tollFeeTitle")}</Typography>
            <Typography variant="body2" color="textSecondary" className={classes.description}>{t("exportSettings:tollFeeDescription")}</Typography>
          </>
        }
      >

        <Grid item container spacing={2}>

          <Grid item xs={12}>
            <Typography variant="subtitle1">{t("exportSettings:tollFeeGroupsTitle")}</Typography>
            <Typography variant="body2" color="textSecondary">{t("exportSettings:tollFeeGroupsDescription")}</Typography>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth size="small" variant="filled" disabled={productGroups.length === 0}>
              <InputLabel>{t("exportSettings:tollFeeSelect")}</InputLabel>
              <Select onChange={ev => setTollFeeId(ev.target.value)} value={tollFeeId}>
                {productGroups.map((group, idx) => <MenuItem selected={group.id === tollFeeId} value={group.id} key={idx}>{group.code}. {group.name}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth size="small" variant="filled" disabled={productGroups.length === 0}>
              <InputLabel>{t("exportSettings:ferryFeeSelect")}</InputLabel>
              <Select onChange={ev => setFerryFeeId(ev.target.value)} value={ferryFeeId}>
                {productGroups.map((group, idx) => <MenuItem selected={group.id === ferryFeeId} value={group.id} key={idx}>{group.code}. {group.name}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Button variant="contained" color="primary" disabled={!changesTollFees || working} onClick={onSave}>
              {t("common:saveChanges")}
            </Button>
          </Grid>
        </Grid>


      </ToggleSurface>

    </ResponsivePage>
  );
}